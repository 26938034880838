import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from 'src/app/Services/Toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastContainerComponent implements OnInit {
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }

  constructor(public toastService: ToastService) { }

  ngOnInit() {
  }

}
