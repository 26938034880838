import { Injectable } from '@angular/core';
import { AuthService } from '../Auth/auth.service';
import { User, ErrorCodes } from 'src/app/Utils/common-classes';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private authService: AuthService) { }


  public getAccount(): Promise<User> {
    return this.authService.getRequest("/get-account", null, true);
  }

  public editAccount(forename: string, surname: string, username: string, oldPassword: string, newPassword: string): Promise<User> {
    const body = {
      forename: forename,
      surname: surname,
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    return this.authService.postRequest("/edit-account", body, true);
  }
}
