import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { AccountService } from 'src/app/Services/Account/account.service';
import { User, ToastTypes } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountComponent implements OnInit {
  public editAccountForm: FormGroup;
  public editPasswordForm: FormGroup;
  public accounEditError: string;
  public passwordEditError: string;
  public formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    public utils: UtilsService,
    public accountService: AccountService,
    public authService: AuthService) {
    //prendo le info dell'utente
  }

  ngOnInit() {
    let _self = this;
    //TODO spostare tutto nei rispettivi componenti
    _self.initEditPasswordForm();
    _self.utils.showShortLoader();
    _self.accountService.getAccount().then((user: User) => {
      _self.initEditAccountForm(user);
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public editAccountInfo(): void {
    let _self = this;
    const forename: string = _self.editAccountForm.get('forename').value;
    const surname: string = _self.editAccountForm.get('surname').value;
    const username: string = _self.editAccountForm.get('username').value;
    if (_self.editAccountForm.valid) {
      _self.utils.showShortLoader();
      _self.accountService.editAccount(forename, surname, username, null, null).then((user: User) => {
        _self.utils.showMessageToast("account.success.user-info-updated", ToastTypes.SUCCESS);
        _self.initEditAccountForm(user);
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    }
  }

  public editAccountPassword(): void {
    let _self = this;
    const oldPassword: string = _self.editPasswordForm.get('oldPassword').value;
    const newPassword: string = _self.editPasswordForm.get('newPassword').value;
    const confirmPassword: string = _self.editPasswordForm.get('confirmPassword').value;

    if (newPassword != confirmPassword) {
      _self.utils.showMessageToast("register.error.invalid-confirm-password", ToastTypes.ERROR);
      return;
    }

    if (_self.editPasswordForm.valid) {
      _self.utils.showShortLoader();
      _self.accountService.editAccount(null, null, null, oldPassword, newPassword).then((user: User) => {
        _self.utils.showMessageToast("account.success.user-password-updated", ToastTypes.SUCCESS);
        _self.initEditPasswordForm();
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    }
  }

  private initEditAccountForm(user: User): void {
    let _self = this;
    _self.editAccountForm = _self.fb.group({
      username: [user.username, Validators.required],
      forename: [user.forename, Validators.required],
      surname: [user.surname, Validators.required]
    });
  }

  private initEditPasswordForm(): void {
    let _self = this;
    _self.editPasswordForm = _self.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

}
