import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { Channel, StatusCodes } from 'src/app/Utils/common-classes';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';

@Component({
  selector: 'app-online-channels',
  templateUrl: './online-channels.component.html',
  styleUrls: ['./online-channels.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OnlineChannelsComponent implements OnInit {
  public onlineChannels: Array<Channel> = [];

  constructor(
    private channelService: ChannelService,
    private router: Router,
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService
  ) { }

  ngOnInit() {
    let _self = this;
    _self.utils.showShortLoader();
    _self.channelService.getChannels().then((channels: Array<Channel>) => {
      channels.forEach((channel: Channel) => {
        if (channel.status == StatusCodes.STARTED) {
          _self.onlineChannels.push(channel);
        }
      });
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public openChannel(channel: Channel): void {
    this.router.navigate(['channel/' + channel.channelId]);
  }

}
