import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ToastTypes } from 'src/app/Utils/common-classes';
import { AuthService } from 'src/app/Services/Auth/auth.service';


@Component({
  selector: 'app-forgot-your-password',
  templateUrl: './forgot-your-password.component.html',
  styleUrls: ['./forgot-your-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotYourPasswordComponent implements OnInit {
  public form: FormGroup;
  public formSubmitAttempt: boolean;
  public isShow: boolean = false;

  constructor(
    private fb: FormBuilder,
    public fontAwesomeService: FontAwesomeService,
    private authService: AuthService,
    public utils: UtilsService

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      usernameEmail: ['', Validators.required]

    });
  }

  async onSubmit() {
    let _self = this;
    _self.formSubmitAttempt = false;
    if (_self.form.valid) {
      const usernameEmail = _self.form.get('usernameEmail').value;
      _self.authService.forgotPassword(usernameEmail).then(() => {
        _self.isShow = true;
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.utils.showMessageToast("register.error.invalid-form", ToastTypes.ERROR);
    }
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }
}
