import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { ErrorCodes, JoinChannelPayload, SocialAuthMethods } from 'src/app/Utils/common-classes';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { ChannelService } from 'src/app/Services/Channel/channel.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  public form: FormGroup;
  public loginError: string;
  public formSubmitAttempt: boolean;
  private returnUrl: string;
  public lang: string;
  private channelInviteCode: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private channelService: ChannelService,
    private socialAuthService: SocialAuthService,
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService,
    public afAuth: AngularFireAuth
  ) { }


  ngOnInit() {
    this.channelInviteCode = this.route.snapshot.params.channelInviteCode;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (this.authService.getAccessToken() && this.authService.getApplicationData()) {
      this.router.navigate([this.returnUrl]);
    }
  }

  async onSubmit() {
    let _self = this;
    _self.loginError = "";
    _self.formSubmitAttempt = false;
    if (_self.form.valid) {
      _self.utils.showShortLoader();
      const username = _self.form.get('username').value;
      const password = _self.form.get('password').value;
      _self.authService.login(username, password).then(() => {
        _self.loginError = "";
        if (_self.channelInviteCode) {
          return new Promise((resolve, reject) => {
            let url: string = 'home';
            _self.channelService.joinChannel(_self.channelInviteCode).then((data: JoinChannelPayload) => {
              url = 'channel/' + data.channelId;
              return Promise.resolve();
            }).catch((err) => {
              return _self.utils.getErrorShowToast(err);
            }).finally(() => {
              _self.router.navigate([url]);
              resolve(null);
            })
          });
        }
        _self.router.navigate(['home']);
      }).catch((err) => {
        return _self.utils.getError(err);
      }).then((translatedError: string) => {
        if (translatedError) {
          _self.loginError = translatedError;
        }
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.formSubmitAttempt = true;
      _self.loginError = "general.error.user-not-found";
    }
  }

  public signInWithGoogle(): void {
    let _self = this;
    _self.utils.showShortLoader();
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: SocialUser) => {
      return _self.authService.loginSocial(data.idToken, SocialAuthMethods.GOOGLE, null);
    }).then((data) => {
      _self.loginError = "";
      if (_self.channelInviteCode) {
        return new Promise((resolve, reject) => {
          let url: string = 'home';
          _self.channelService.joinChannel(_self.channelInviteCode).then((data: JoinChannelPayload) => {
            url = 'channel/' + data.channelId;
            return Promise.resolve();
          }).catch((err) => {
            return _self.utils.getErrorShowToast(err);
          }).finally(() => {
            _self.router.navigate([url]);
            resolve(null);
          })
        });
      }
      _self.router.navigate(['home']);
    }).catch((err) => {

      if (err && err.error == "popup_closed_by_user") {
        return Promise.resolve();
      }

      return new Promise<void>((resolve, reject) => {
        _self.utils.getError(err).then((translatedError) => {
          if (translatedError) {
            _self.loginError = translatedError;
          }
          resolve();
        });
      });
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public signInWithFacebook(): void {
    let _self = this;
    _self.utils.showShortLoader();
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: SocialUser) => {
      return _self.authService.loginSocial(data.authToken, SocialAuthMethods.FACEBOOK, null);
    }).then((data) => {
      _self.loginError = "";
      if (_self.channelInviteCode) {
        return new Promise((resolve, reject) => {
          let url: string = 'home';
          _self.channelService.joinChannel(_self.channelInviteCode).then((data: JoinChannelPayload) => {
            url = 'channel/' + data.channelId;
            return Promise.resolve();
          }).catch((err) => {
            return _self.utils.getErrorShowToast(err);
          }).finally(() => {
            _self.router.navigate([url]);
            resolve(null);
          })
        });
      }
      _self.router.navigate(['home']);
    }).catch((err) => {
      if (err == "User cancelled login or did not fully authorize.") {
        return Promise.resolve();
      }

      return new Promise<void>((resolve, reject) => {
        _self.utils.getError(err).then((translatedError) => {
          if (translatedError) {
            _self.loginError = translatedError;
          }
          resolve();
        });
      });
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }
}