import { Injectable, TemplateRef } from '@angular/core';
import { ToastTypes } from 'src/app/Utils/common-classes';
import { UtilsService } from '../Utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }
  toasts: any[] = [];

  public show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  public remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}
