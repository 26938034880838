import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VideoJsPlayerOptions } from 'video.js';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoPlayerComponent } from 'src/app/Components/video-player/video-player.component';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { ChannelInfo, Channel, ChatMessagePayload, User, StatusCodes, TranslationData } from 'src/app/Utils/common-classes';
import * as io from 'socket.io-client';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SocketEmitterService } from 'src/app/Services/SocketEmitter/socket-emitter.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { environment } from 'src/environments/environment';
import { AgoraClient, ClientEvent, ConnectionState, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { UploadAttachmentComponent } from 'src/app/Components/upload-attachment/upload-attachment.component';
import { ChannelAttachmentsComponent } from 'src/app/Components/channel-attachments/channel-attachments.component';
declare var $: any;

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss'],
  entryComponents: [UploadAttachmentComponent, ChannelAttachmentsComponent],
  encapsulation: ViewEncapsulation.None
})
export class ChannelComponent implements OnInit, OnDestroy {
  @ViewChild("videoPlayer", { static: true, read: VideoPlayerComponent })
  public videoPlayerComponent: VideoPlayerComponent;

  @ViewChild('chatModerationScroller', { static: false, read: ElementRef })
  public chatModerationScroller: ElementRef;

  @ViewChild('chatScroller', { static: true, read: ElementRef })
  public chatScroller: ElementRef;

  @ViewChild('overlayChat', { static: true, read: ElementRef })
  public overlayChat: ElementRef;

  public videoJsOptions: VideoJsPlayerOptions = {
    autoplay: false,
    controls: false,
    preload: "none"
  };
  public channelId: string;
  public channelInfo: ChannelInfo;
  public socket: SocketIOClient.Socket;
  public chatForm: FormGroup;
  public chatMessages: Array<ChatMessagePayload> = [];
  public chatMessageRequests: Array<ChatMessagePayload> = [];


  public channelOnline: boolean;
  public chatHidden: boolean;
  public moderator: boolean;
  public fullscreenChat: boolean;
  public wasFullScreen: boolean;
  public userListShowing: boolean;

  public chatModerationDisableScroll: boolean = false;
  public chatDisableScroll: boolean = false;
  public reconnecting: boolean = false;
  public leaving: boolean = false;

  //traduttori
  private agoraClient: AgoraClient;
  public agoraConnected: boolean = false;
  private localStream: Stream;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private channelService: ChannelService,
    private socketEmitter: SocketEmitterService,
    public utils: UtilsService,
    private router: Router,
    public fontAwesomeService: FontAwesomeService,
    private agoraService: NgxAgoraService,
    private modalService: NgbModal) {


  }


  onAction(event): void {
    console.log(event);
    if (event) {
      this.overlayChat.nativeElement.classList.add('overlayChat');

      // console.log(this.videoPlayerComponent);
    }
  }
  ngOnDestroy() {
    this.removeTranslator();
    this.leaving = true;
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  ngOnInit() {
    let _self = this;
    _self.channelId = _self.route.snapshot.params.channelId;
    _self.channelService.getChannel(_self.channelId).then((channelInfo: ChannelInfo) => {
      _self.channelInfo = channelInfo;
      //controllo se sono un amministratore
      if (channelInfo.channel.status == StatusCodes.STARTED) {
        _self.channelOnline = true;
        _self.videoJsOptions.sources = [{
          src: channelInfo.channel.url,
          type: 'application/x-mpegURL'
        }];
        _self.videoJsOptions.controls = true;
        _self.videoJsOptions.autoplay = true;
        _self.videoPlayerComponent.setVideo(_self.videoJsOptions.sources);
      } else {
        //todo
      }
      let user: User = _self.authService.getApplicationData().user;
      if (ChannelInfo.isModerator(channelInfo, user.userId)) {
        _self.moderator = true;
      }

      _self.initSocket();
    }).catch((err) => {
      console.error("todo err", err);
      _self.router.navigate(['404']);
    });

    _self.chatForm = _self.fb.group({
      message: ['']
    });

    /*
    $(document).ready(function () {
      var bsDefaults = {
        offset: false,
        overlay: true,
        width: '100%'
      },
        bsMain = $('.bs-offset-main'),
        bsOverlay = $('.bs-canvas-overlay');

      $('[data-toggle="canvas"][aria-expanded="false"]').on('click', function () {
        var canvas = $(this).data('target'),
          opts = $.extend({}, bsDefaults, $(canvas).data()),
          prop = $(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left';

        if (opts.width === '100%')
          opts.offset = false;

        $(canvas).css('width', opts.width);
        if (opts.offset && bsMain.length)
          bsMain.css(prop, opts.width);

        $(canvas + ' .bs-canvas-close').attr('aria-expanded', "true");
        $('[data-toggle="canvas"][data-target="' + canvas + '"]').attr('aria-expanded', "true");
        if (opts.overlay && bsOverlay.length)
          bsOverlay.addClass('show');
        return false;
      });

      $('.bs-canvas-close, .bs-canvas-overlay').on('click', function () {
        var canvas, aria;
        if ($(this).hasClass('bs-canvas-close')) {
          canvas = $(this).closest('.bs-canvas');
          aria = $(this).add($('[data-toggle="canvas"][data-target="#' + canvas.attr('id') + '"]'));
          if (bsMain.length)
            bsMain.css(($(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left'), '');
        } else {
          canvas = $('.bs-canvas');
          aria = $('.bs-canvas-close, [data-toggle="canvas"]');
          if (bsMain.length)
            bsMain.css({
              'margin-left': '',
              'margin-right': ''
            });
        }
        canvas.css('width', '');
        aria.attr('aria-expanded', "false");
        if (bsOverlay.length)
          bsOverlay.removeClass('show');
        return false;
      });
    });*/
  }

  private initSocket(): void {
    let _self = this;
    /*
    localStorage.debug = '*';
    localStorage.setItem("debug", '*');
    */

    if (_self.socket != null) {
      if (_self.socket.connected) {
        _self.socket.disconnect();
      }
    }
    let options: SocketIOClient.ConnectOpts = {
      query: "token=" + _self.authService.getAccessToken(),
      forceNew: true,
      reconnection: false,
      //transports: ['websocket'],
      //upgrade: false
    }

    _self.socket = io.connect(environment.socketApiUrl + "/socket-io/" + _self.channelId, options);
    _self.socketEmitter.initSocket(_self.socket);
    _self.socketOn();
  }

  private socketOn() {
    let _self = this;
    _self.socket.on("error", (err) => {
      console.error(err);
    });

    _self.socket.on("connect", () => {
      _self.reconnecting = false;
    });

    _self.socket.on("disconnect", () => {
      //console.error("disconnect");
      setTimeout(() => {
        if (!_self.leaving) {
          //console.warn("provo a riconnettermi");
          _self.reconnecting = true;
          _self.initSocket();
        }
      }, 1000);
    });

    _self.socket.on('connect_error', (err) => {
      //console.error('connect_error', err);
      setTimeout(() => {
        if (!_self.leaving) {
          _self.reconnecting = true;
          _self.initSocket();
        }
      }, 1000);
    });

    _self.socket.on("chat-message", (message: ChatMessagePayload) => {
      _self.chatMessages.push(message);

      let chatMessageRequestToRemove: ChatMessagePayload = _self.chatMessageRequests.find((chatMessageRequest: ChatMessagePayload) => {
        return message.message.channelChatMessageId == chatMessageRequest.message.channelChatMessageId;
      });

      let index: number = _self.chatMessageRequests.indexOf(chatMessageRequestToRemove);
      if (index > -1) {
        _self.chatMessageRequests.splice(index, 1);
      }

      setTimeout(() => {
        _self.scrollToBottom(_self.chatScroller, _self.chatDisableScroll);
      }, 1);
    });
    if (_self.moderator) {
      _self.socket.on("chat-message-request", (message: ChatMessagePayload) => {
        _self.chatMessageRequests.push(message);

        setTimeout(() => {
          _self.scrollToBottom(_self.chatModerationScroller, _self.chatModerationDisableScroll);
        }, 1);
      });
    }

    _self.socket.on("chat-message-delete", (channelChatMessageId: string) => {

      let chatMessageToRemove: ChatMessagePayload = _self.chatMessages.find((chatMessage: ChatMessagePayload) => {
        return channelChatMessageId == chatMessage.message.channelChatMessageId;
      });

      let chatMessageIndex: number = _self.chatMessages.indexOf(chatMessageToRemove);
      if (chatMessageIndex > -1) {
        _self.chatMessages.splice(chatMessageIndex, 1);
      }

      let chatMessageRequestToRemove: ChatMessagePayload = _self.chatMessageRequests.find((chatMessageRequest: ChatMessagePayload) => {
        return channelChatMessageId == chatMessageRequest.message.channelChatMessageId;
      });

      let chatMessageRequestindex: number = _self.chatMessageRequests.indexOf(chatMessageRequestToRemove);
      if (chatMessageRequestindex > -1) {
        _self.chatMessageRequests.splice(chatMessageRequestindex, 1);
      }
    });

    _self.socket.on("user-connected", (user: User) => {
      _self.channelInfo.onlineUsers.push(user);
    });

    _self.socket.on("user-disconnected", (user: User) => {
      _self.channelInfo.onlineUsers = _self.channelInfo.onlineUsers.filter((onlineUser: User) => {
        return onlineUser.userId != user.userId;
      });
    });

    _self.socket.on("streaming-started", () => {
      console.log("STARTED");
    });

    _self.socket.on("streaming-stopped", () => {
      console.log("STOPPED");
    });
  }

  public setTranslator() {
    this.initTranslation({ host: true, translationId: "TODOID_IT", translationLangCode: "IT", translationName: "Italian" });
  }

  public setListneren() {
    this.initTranslation({ host: false, translationId: "TODOID_IT", translationLangCode: "IT", translationName: "Italian" });
  }


  public removeTranslator() {
    if (this.agoraClient) {
      let state: ConnectionState = this.agoraClient.getConnectionState();
      if (this.localStream) {
        this.localStream.close();
      }

      if (state === "CONNECTED") {
        this.agoraClient.leave();
        //this.agoraClient.stopProxyServer();
      }
    }
  }
  /**
   * Inizializza i traduttori.
   *
   *
   * @param translationData - le informazioni del traduttore
   * @returns null
   *
   */
  public initTranslation(translationData: TranslationData) {
    let _self = this;

    _self.agoraClient = _self.agoraService.createClient({ mode: "live", codec: "vp8" });
    //_self.agoraClient.startProxyServer();

    if (translationData.host) {
      //TODO connetto con webrtc il traduttore agli utenti
      _self.agoraClient.setClientRole("host");
      _self.localStream = _self.agoraService.createStream({ audio: true, video: false, screen: false });

      //Todo authToken, todo 
      _self.agoraClient.join(null, translationData.translationId, null, (uuid) => {
        console.log("initTranslation host success", uuid);

        _self.localStream.init(() => {
          console.error("success init localStream");
          //_self.localStream.play("test-audio", { muted: false });
          _self.agoraClient.publish(_self.localStream, (error) => {
            console.error("error on publish local stream", error);
          });

        }, (error) => {
          console.error("error init localStream", error);
        });

      }, (error) => {
        console.error("initTranslation host error", error);
      });

      /*
      _self.localStream.on(StreamEvent.MediaAccessAllowed, () => {
        console.log("MediaAccessAllowed");
      });

      //TODO gestione mediaaccessDenied
      //chrome://settings/content/microphone differenziare per browser
      _self.localStream.on(StreamEvent.MediaAccessDenied, () => {
        console.log("MediaAccessDenied");
      });
      */
    } else {
      _self.agoraClient.setClientRole("audience");
      _self.agoraClient.join(null, translationData.translationId, null, (uuid) => {
        console.log("initTranslation audience success", uuid);
      }, (error) => {
        console.error("initTranslation audience error", error);
      });
    }

    _self.agoraClient.on(ClientEvent.ConnectionStateChanged, function (evt) {
      console.log("ConnectionStateChanged", _self.agoraClient.getConnectionState(), evt);
      setTimeout(() => {
        switch (_self.agoraClient.getConnectionState()) {
          case "CONNECTED":
            _self.agoraConnected = true;
            break;
          case "DISCONNECTED":
            _self.agoraConnected = false;
            break;
          default:
            break;
        }
      }, 100);
    })

    _self.agoraClient.on(ClientEvent.RemoteStreamAdded, function (evt) {
      let stream: Stream = evt.stream;
      _self.agoraClient.subscribe(stream, null, (err) => {
        console.error("error on subscribe stream", err);
      });
    });

    _self.agoraClient.on(ClientEvent.RemoteStreamRemoved, function (evt) {
      let stream: Stream = evt.stream;
      stream.stop(); // stop the stream
      stream.close(); // clean up and close the camera stream
      console.log("Remote stream is removed " + stream.getId());
    });

    _self.agoraClient.on(ClientEvent.RemoteStreamSubscribed, function (evt) {
      let stream: any = evt.stream;
      //stream.stream posso usare per settare srcObject
      let audio: HTMLAudioElement = <HTMLAudioElement>document.getElementById("rtc-audio");
      audio.srcObject = stream.stream;
      audio.play();
      //stream.play("test-audio");
      function handleDataAvailable(event) {
        console.log("data-available");
        if (event.data.size > 0) {
          console.log(event);
        }
      }
    });

    //live transcoding events..
    _self.agoraClient.on(ClientEvent.LiveStreamingStarted, function (evt) {
      console.log("Live streaming started");
    });

    _self.agoraClient.on(ClientEvent.LiveStreamingFailed, function (evt) {
      console.log("Live streaming failed");
    });

    _self.agoraClient.on(ClientEvent.LiveStreamingStopped, function (evt) {
      console.log("Live streaming stopped");
    });

    _self.agoraClient.on(ClientEvent.LiveTranscodingUpdated, function (evt) {
      console.log("Live streaming updated");
    });

    // ingested live stream 
    _self.agoraClient.on(ClientEvent.StreamInjectedStatusUpdated, function (evt) {
      console.log("Injected Steram Status Updated");
      console.log(JSON.stringify(evt));
    });

    // when a remote stream leaves the channel
    _self.agoraClient.on(ClientEvent.PeerLeave, function (evt) {
      console.log('Remote stream has left the channel: ' + evt.stream.getId());
    });

    // show mute icon whenever a remote has muted their mic
    _self.agoraClient.on(ClientEvent.RemoteAudioMuted, function (evt) {
      console.log('Mute Audio for: ' + evt.uid);
    });

    _self.agoraClient.on(ClientEvent.RemoteAudioUnmuted, function (evt) {
      console.log('Unmute Audio for: ' + evt.uid);
    });
  }

  public openUploadDialog(): void {
    const modalRef: NgbModalRef = this.modalService.open(UploadAttachmentComponent, { centered: true, backdrop: "static", keyboard: false });
    const instance: UploadAttachmentComponent = modalRef.componentInstance;
    instance.channelId = this.channelId;
  }

  public openAttachmentsDialog(): void {
    const modalRef: NgbModalRef = this.modalService.open(ChannelAttachmentsComponent, { centered: true, backdrop: "static", keyboard: false });
    const instance: ChannelAttachmentsComponent = modalRef.componentInstance;
    instance.channelId = this.channelId;
  }

  public showChat(): void {
    this.chatHidden = false;
    if (this.wasFullScreen) {
      this.fullscreenChat = true;
    }
  }

  public hideChat(): void {
    this.chatHidden = true;
    this.fullscreenChat = false;
  }

  public toggleFullscreenChat(): void {
    this.fullscreenChat = !this.fullscreenChat;
    this.wasFullScreen = this.fullscreenChat;
  }

  public onChatMessageSubmit(): void {
    let _self = this;
    let message: string = _self.chatForm.get("message").value ? _self.chatForm.get("message").value.trim() : '';
    if (message) {
      _self.socketEmitter.sendChatMessage(message);
      _self.chatForm.reset();
    }
  }

  public acceptChatMessage(chatMessageId: string): void {
    this.socketEmitter.sendAcceptChatMessage(chatMessageId);
  }

  public rejectChatMessage(chatMessageId: string): void {
    this.socketEmitter.sendRejectChatMessage(chatMessageId);

  }

  public onChatScroll() {
    let element: any = this.chatScroller.nativeElement;
    let atBottom: boolean = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.chatDisableScroll = false;
    } else {
      this.chatDisableScroll = true;
    }
  }

  public onChatModetationScroll() {
    let element: any = this.chatModerationScroller.nativeElement;
    let atBottom: boolean = element.scrollHeight - element.scrollTop === element.clientHeight;
    if (atBottom) {
      this.chatModerationDisableScroll = false;
    } else {
      this.chatModerationDisableScroll = true;
    }
  }

  private scrollToBottom(elementRef: ElementRef, disableScroll: boolean): void {
    if (disableScroll) {
      return
    }
    try {
      elementRef.nativeElement.scrollTop = elementRef.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  public openUserList() {
    this.userListShowing = true;
  }

  public closeUserList() {
    this.userListShowing = false;
  }

}
