import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ToastTypes } from 'src/app/Utils/common-classes';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup;
  public registerError: string;
  public formSubmitAttempt: boolean;
  private resetPasswordToken: string;
  private returnUrl: string;
  public isShow: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService
  ) { }

  ngOnInit() {
    this.resetPasswordToken = this.route.snapshot.params.token;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';
    this.form = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['']
    });

    if (this.authService.getAccessToken() && this.authService.getApplicationData()) {
      this.router.navigate([this.returnUrl]);
    }
  }

  public onSubmit() {
    let _self = this;

    const password: string = _self.form.get('password').value;
    const confirmPassword: string = _self.form.get("confirmPassword").value;

    if (password != confirmPassword) {
      _self.utils.showMessageToast("register.error.invalid-confirm-password", ToastTypes.ERROR);
      return;
    }


    if (_self.form.valid) {
      _self.authService.resetPassword(password, _self.resetPasswordToken).then(() => {
        _self.isShow = true;
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.utils.showMessageToast("register.error.invalid-form", ToastTypes.ERROR);
    }
  }


  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

}
