import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  navbarOpen = false;
  

  constructor(
    public fontAwesomeService: FontAwesomeService,
    public router: Router,
    public authService: AuthService

  ) { }
  

  ngOnInit() {
    $(document).ready(function () {
  
      'use strict';
      
       var c, currentScrollTop = 0,
           navbar = $('nav');
    
       $(window).scroll(function () {
          var a = $(window).scrollTop();
          var b = navbar.height();
         
          currentScrollTop = a;
         
          if (c < currentScrollTop && a > b + b) {
            navbar.addClass("scrollUp");
          } else if (c > currentScrollTop && !(a <= b)) {
            navbar.removeClass("scrollUp");
          }
          c = currentScrollTop;
      });
      
    });
  }
  
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  
  public logout(): void {
    let _self = this;
    _self.authService.logout().then(() => {
      _self.router.navigate(['login']);
    });
  }

}
