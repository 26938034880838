import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/Services/Account/account.service';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ErrorCodes, JoinChannelPayload } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-join-channel',
  templateUrl: './join-channel.component.html',
  styleUrls: ['./join-channel.component.scss']
})
export class JoinChannelComponent implements OnInit {
  public loading: boolean = true;
  public form: FormGroup;
  public joinError: string;
  public formSubmitAttempt: boolean;
  public lang: string;
  private channelInviteCode: string;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private channelService: ChannelService,
    private accountService: AccountService,
    public utils: UtilsService,
    public authService: AuthService) { }

  ngOnInit() {
    this.channelInviteCode = this.route.snapshot.params.channelInviteCode;
    this.form = this.fb.group({
      forename: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', this.customEmailValidator],
    });

    //se esiste verifico il token
    if (this.authService.getAccessToken()) {
      this.utils.showShortLoader();
      this.accountService.getAccount().then((data) => {
        //se è valido aggiungo e faccio il redirect all'evento
        return this.channelService.joinChannel(this.channelInviteCode);
      }).then((data: JoinChannelPayload) => {
        this.router.navigate(['channel/' + data.channelId]);
      }).catch((err) => {
        console.error(err);
        if (UtilsService.getErrorString(err) == ErrorCodes.SESSION_NOT_FOUND) {
          //porto al login con il codice
          this.authService.logoutSync();
          return this.router.navigate(['login/' + this.channelInviteCode]);
        }

        return new Promise<any>((resolve, reject) => {
          this.utils.getErrorShowToast(err).then(() => {
            return this.router.navigate(['404']);
          }).then(() => {
            resolve(null);
          }).catch(() => {
            resolve(null);
          });
        })
        //porto alla pagina errore del server
      }).finally(() => {
        this.utils.hideShortLoader();
      });
    }
  }

  async onSubmit() {
    let _self = this;
    _self.joinError = "";
    _self.formSubmitAttempt = false;
    if (_self.form.valid) {
      _self.utils.showShortLoader();
      const forename = _self.form.get('forename').value;
      const surname = _self.form.get('surname').value;
      const email = _self.form.get('email').value;
      _self.channelService.joinChannel(_self.channelInviteCode, forename, surname, email).then((data: JoinChannelPayload) => {
        _self.joinError = "";
        if (data && data.accessToken && data.applicationData) {
          data.applicationData.channelId = data.channelId;
          _self.authService.setAccessToken(data.accessToken);
          _self.authService.setApplicationData(data.applicationData);
        }
        _self.router.navigate(['channel/' + data.channelId]);
      }).catch((err) => {
        return _self.utils.getError(err);
      }).then((translatedError) => {
        if (translatedError) {
          _self.joinError = translatedError;
        }
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.formSubmitAttempt = true;
      _self.joinError = "general.error.user-not-found";
    }
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

  private customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }
}
