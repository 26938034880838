import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { Channel, StatusCodes } from 'src/app/Utils/common-classes';
import { Router } from '@angular/router';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';

@Component({
  selector: 'app-channels-carousels',
  templateUrl: './channels-carousels.component.html',
  styleUrls: ['./channels-carousels.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChannelsCarouselsComponent implements OnInit {
  public offlineChannels: Array<Channel> = [];
  public onlineChannels: Array<Channel> = [];

  constructor(
    private channelService: ChannelService,
    private router: Router,
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService
  ) { }

  ngOnInit() {
    let _self = this;
    _self.utils.showShortLoader();
    _self.channelService.getChannels().then((channels: Array<Channel>) => {

      for (let i = 0; i < channels.length; i++) {
        const channel: Channel = channels[i];
        if (channel.status == StatusCodes.STARTED) {
          _self.onlineChannels.push(channel);
        } else {
          _self.offlineChannels.push(channel);
        }
      }
      if (_self.onlineChannels.length > 6) {
        _self.onlineChannels = _self.onlineChannels.splice(0, 5);
      }


      if (_self.offlineChannels.length > 6) {
        _self.offlineChannels = _self.offlineChannels.splice(0, 5);
      }

    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public openChannel(channel: Channel): void {
    this.router.navigate(['channel/' + channel.channelId]);
  }

}
