import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/Utils/utils.service';


@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  public lang: string;
  

  constructor( public utils: UtilsService ) { 
    
  }

  ngOnInit() {
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

}
