import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { ChannelComponent } from './Pages/channel/channel.component';

import { LoginComponent } from './Pages/login/login.component';
import { AuthGuardService } from './Services/AuthGuard/auth-guard.service';
import { AuthGuardAdminService } from './Services/AuthGuardAdmin/auth-guard-admin.service';
import { HomeComponent } from './Pages/home/home.component';
import { RegisterComponent } from './Pages/register/register.component';
import { ThankYouComponent } from './Pages/thank-you/thank-you.component';
import { ForgotYourPasswordComponent } from './Pages/forgot-your-password/forgot-your-password.component';
import { AccountComponent } from './Pages/account/account.component';
import { ManageChannelsComponent } from './Pages/manage-channels/manage-channels.component';
import { CreateChannelComponent } from './Pages/create-channel/create-channel.component';
import { OnlineChannelsComponent } from './Pages/online-channels/online-channels.component';
import { OfflineChannelsComponent } from './Pages/offline-channels/offline-channels.component'
import { ManageUsersComponent } from './Pages/manage-users/manage-users.component';
import { ChangePasswordComponent } from './Pages/change-password/change-password.component';
import { LanguageComponent } from './Pages/language/language.component';
import { ConfirmRegistrationComponent } from './Pages/confirm-registration/confirm-registration.component';
import { JoinChannelComponent } from './Pages/join-channel/join-channel.component';

OfflineChannelsComponent
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'login/:channelInviteCode',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'register/:userInviteToken',
    component: RegisterComponent,
  },
  {
    path: 'register/:userInviteToken/:channelInviteCode',
    component: RegisterComponent,
  },
  {
    path: 'thank-you',
    component: ThankYouComponent,
  },
  {
    path: 'forgot-your-password',
    component: ForgotYourPasswordComponent,
  },
  {
    path: 'change-password/:token',
    component: ChangePasswordComponent,
  },
  {
    path: 'confirm-registration/:token',
    component: ConfirmRegistrationComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "online-channels",
    component: OnlineChannelsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "offline-channels",
    component: OfflineChannelsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "settings/profile",
    component: AccountComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "settings/users",
    component: ManageUsersComponent,
    canActivate: [AuthGuardAdminService]
  },
  {
    path: "settings/channels",
    component: ManageChannelsComponent,
    canActivate: [AuthGuardAdminService]
  },
  {
    path: "settings/language",
    component: LanguageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "settings/create-channel",
    component: CreateChannelComponent,
    canActivate: [AuthGuardAdminService]
  },
  {
    path: 'channel/:channelId',
    component: ChannelComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'join/:channelInviteCode',
    component: JoinChannelComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
