import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Channel, ChannelInfo, User, ToastTypes, CancelAsyncToken } from 'src/app/Utils/common-classes';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageChannelsService } from 'src/app/Services/Admin/ManageChannels/manage-channels.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateUsersComponent } from 'src/app/Components/create-users/create-users.component';
import { InviteUsersComponent } from 'src/app/Components/invite-users/invite-users.component';

@Component({
  selector: 'app-manage-channels',
  templateUrl: './manage-channels.component.html',
  styleUrls: ['./manage-channels.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageChannelsComponent implements OnInit, OnDestroy {
  public channels: Array<Channel | any> = [];
  public page = 1;
  public listChannels: Array<Channel>;
  public pageSize = 10;
  public collectionSize = 0;
  public filter = new FormControl('');
  private cancelAsyncToken: CancelAsyncToken;

  public selectedChannel: ChannelInfo;
  public selectedUsers: Array<User>;
  public editSelectedChannelForm: FormGroup;
  public selectedAction: number = 0;
  public showPassword: boolean = false;

  public addedUsersSelectedAction: number = 0;
  public platformUsersSelectedAction: number = 0;
  public addedUsers: Array<User> = [];
  public platformUsers: Array<User> = [];
  public selectedAddedUsers: Array<User> = [];
  public selectedPlatformUsers: Array<User> = [];

  private fragmentSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private manageChannelsService: ManageChannelsService,
    public fontAwesomeService: FontAwesomeService,
    public utils: UtilsService,
    public route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    let _self = this;
    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      _self.navChanged(fragment);
    });
  }

  ngOnDestroy(): void {
    if (this.cancelAsyncToken) {
      this.cancelAsyncToken.cancel = true;
    }
    this.fragmentSubscription.unsubscribe();
  }

  ngOnInit() {
    //this.navChanged(this.route.snapshot.fragment);
  }

  public navChanged(fragment: string) {
    let _self = this;
    _self.selectedChannel = null;
    _self.selectedUsers = null;
    _self.listChannels = null;
    _self.page = 1;
    _self.channels = [];
    _self.filter = new FormControl('');

    if (_self.cancelAsyncToken) {
      _self.cancelAsyncToken.cancel = true;
    }

    _self.initChannels(fragment);
  }

  private initChannels(fragment: string): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageChannelsService.getChannelsAdmin().then((data: Array<Channel>) => {
      _self.channels = data;
      _self.refreshChannels();

      if (_self.channels && _self.channels.length > 0) {
        switch (fragment) {
          case "ManageChannelInvites":
            _self.selectChannelManageChannelInvites(_self.channels[0]);
            break;
          case "EditChannels":
            _self.selectChannelEditChannel((_self.channels[0]));
            break;
          default:
            break;
        }
      }
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  /** GESTIONE UTENTI **/

  public selectChannelManageChannelInvites(channel: Channel): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageChannelsService.getChannelAdmin(channel.channelId).then((data: ChannelInfo) => {
      _self.selectedChannel = data;
      _self.selectedUsers = null;
      return _self.manageChannelsService.getUsers();
    }).then((data: Array<User>) => {
      _self.addedUsers = [];
      _self.platformUsers = [];
      if (_self.selectedChannel.channel.users || _self.selectedChannel.channel.users.length) {
        _self.addedUsers = _self.selectedChannel.channel.users;
      }
      for (let i = 0; i < _self.addedUsers.length; i++) {
        let element: User = _self.addedUsers[i];
        if (element.userId == _self.selectedChannel.channel.userId) {
          element.owner = true;
          element.moderator = true;
        }

        let userToRemove: User = data.find((user: User) => {
          return (user.userId == element.userId || user.tempUserCode);
        });

        if (userToRemove) {
          let userToRemoveIndex: number = data.indexOf(userToRemove);
          data.splice(userToRemoveIndex, 1);
        }
      }

      _self.platformUsers = data;
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public addedUsersAction(): void {
    let _self = this;
    switch (_self.addedUsersSelectedAction) {
      case 1:
        _self.changeAddedUsersStatus(true);
        break;
      case 2:
        _self.changeAddedUsersStatus(false);
        break;
      case 5:
        _self.removeAddedUsers();
        break;
      default:
        break;
    }
    setTimeout(() => {
      _self.addedUsersSelectedAction = 0;
    }, 1);
  }

  public onAddedUsersCheckToggle(): void {
    let _self = this;
    _self.selectedAddedUsers = _self.addedUsers.filter((user: User) => {
      return user.checked;
    });
  }

  private changeAddedUsersStatus(moderator: boolean): void {
    let _self = this;
    let participants: Array<User> = _self.selectedAddedUsers;
    _self.selectedAddedUsers = [];
    _self.utils.showShortLoader();
    _self.manageChannelsService.updateChatUserRole(_self.selectedChannel.channel.channelId, participants, moderator).then(() => {
      _self.selectChannelManageChannelInvites(_self.selectedChannel.channel);
      _self.utils.showMessageToast("manage-channels.success.role-updated", ToastTypes.SUCCESS);
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  private removeAddedUsers(): void {
    let _self = this;
    for (let i = 0; i < _self.selectedAddedUsers.length; i++) {
      let addedUserIndex: number = _self.addedUsers.indexOf(_self.selectedAddedUsers[i]);
      if (addedUserIndex > -1) {
        _self.addedUsers.splice(addedUserIndex, 1);
      }
      _self.selectedAddedUsers[i].moderator = false;
      _self.selectedAddedUsers[i].checked = false;
    }

    _self.platformUsers = _self.platformUsers.concat(_self.selectedAddedUsers);

    let participants: Array<User> = _self.addedUsers;
    _self.updateParticipants(participants, false);
  }

  public removeAddedUser(user: User): void {
    let addedUserIndex: number = this.addedUsers.indexOf(user);
    if (addedUserIndex > -1) {
      this.addedUsers.splice(addedUserIndex, 1);
    }
    this.updateParticipants(this.addedUsers, false);
  }

  public platformUsersAction(): void {
    let _self = this;
    switch (_self.platformUsersSelectedAction) {
      case 1:
        _self.addPlatformUsers(false);
        break;
      case 2:
        _self.addPlatformUsers(true);
        break;
      default:
        break;
    }
    setTimeout(() => {
      _self.platformUsersSelectedAction = 0;
    }, 1);
  }

  public onPlatformUsersCheckToggle(): void {
    let _self = this;
    _self.selectedPlatformUsers = _self.platformUsers.filter((user: User) => {
      return user.checked;
    });
  }

  private addPlatformUsers(moderator: boolean): void {
    let _self = this;
    for (let i = 0; i < _self.selectedPlatformUsers.length; i++) {
      let platformUserIndex: number = _self.platformUsers.indexOf(_self.selectedPlatformUsers[i]);
      if (platformUserIndex > -1) {
        _self.platformUsers.splice(platformUserIndex, 1);
      }
      _self.selectedPlatformUsers[i].moderator = moderator;
      _self.selectedPlatformUsers[i].checked = false;
    }

    let participants: Array<User> = _self.addedUsers.concat(_self.selectedPlatformUsers);
    _self.updateParticipants(participants, true);
  }

  public addPlatformUser(user: User): void {
    let participants: Array<User> = this.addedUsers.concat(user);
    this.updateParticipants(participants, true);
  }

  private updateParticipants(participants: Array<User>, add: boolean): void {
    let _self = this;
    _self.selectedPlatformUsers = [];
    _self.selectedAddedUsers = [];
    _self.utils.showShortLoader();
    _self.manageChannelsService.setChannelParticipants(_self.selectedChannel.channel.channelId, participants).then(() => {
      _self.selectChannelManageChannelInvites(_self.selectedChannel.channel);
      if (add) {
        _self.utils.showMessageToast("manage-channels.success.users-added", ToastTypes.SUCCESS);
      } else {
        _self.utils.showMessageToast("manage-channels.success.users-removed", ToastTypes.SUCCESS);
      }
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public openCreateUsersDialog(): void {
    const modalRef: NgbModalRef = this.modalService.open(CreateUsersComponent, { centered: true, backdrop: "static", keyboard: false, size: 'xl' });
    const instance: CreateUsersComponent = modalRef.componentInstance;
    instance.channelId = this.selectedChannel.channel.channelId;
    modalRef.result.then((event: string) => {
      this.selectChannelManageChannelInvites(this.selectedChannel.channel);
    });
  }

  public openInviteLinkDialog(): void {
    this.manageChannelsService.getChannelInvite(this.selectedChannel.channel.channelId).then((data) => {
      let registerUrl: string;
      let joinUrl: string;
      if (data) {
        if (data.userInviteToken && data.channelInviteCode) {
          registerUrl = `https://${window.location.hostname}/register/${data.userInviteToken}/${data.channelInviteCode}`;
        }

        if (data.channelInviteCode) {
          joinUrl = `https://${window.location.hostname}/join/${data.channelInviteCode}`;
        }
      }
      //todo ritorno errore
      const modalRef: NgbModalRef = this.modalService.open(InviteUsersComponent, { centered: true, backdrop: "static", keyboard: false, size: 'xl' });
      const instance: InviteUsersComponent = modalRef.componentInstance;
      instance.registerUrl = registerUrl;
      instance.joinUrl = joinUrl;
    }).catch((err) => {
      this.utils.getErrorShowToast(err);
    }).finally(() => {
      this.utils.hideShortLoader();
    });
  }

  /** GESTIONE UTENTI **/

  /** MODIFICA CANALE **/

  public selectChannelEditChannel(channel: Channel): void {
    let _self = this;
    _self.utils.showShortLoader();

    if (_self.cancelAsyncToken) {
      _self.cancelAsyncToken.cancel = true;
    }
    _self.manageChannelsService.getChannelAdmin(channel.channelId).then((data: ChannelInfo) => {
      _self.selectedChannel = data;
      _self.selectedUsers = null;
      _self.editSelectedChannelForm = _self.fb.group({
        channelName: [_self.selectedChannel.channel.name, Validators.required],
        channelDescription: [_self.selectedChannel.channel.description, null],
        channelDuration: [_self.selectedChannel.channel.duration, Validators.required]
      });

      return _self.manageChannelsService.getChannelStreamingInfo(channel.channelId);
    }).then((data: ChannelInfo) => {
      _self.cancelAsyncToken = new CancelAsyncToken();
      _self.checkChangeStatus(_self.cancelAsyncToken);
      _self.selectedChannel.settings = data.settings;
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public editChannelInfo(): void {
    let _self = this;
    const CHANNEL_NAME: string = _self.editSelectedChannelForm.get('channelName').value;
    const CHANNEL_DESCRIPTION: string = _self.editSelectedChannelForm.get('channelDescription').value;
    const CHANNEL_DURATION: number = _self.editSelectedChannelForm.get('channelDuration').value;
    if (_self.editSelectedChannelForm.valid) {
      _self.utils.showShortLoader();
      _self.manageChannelsService.editChannel(_self.selectedChannel.channel.channelId, CHANNEL_NAME, CHANNEL_DESCRIPTION, CHANNEL_DURATION).then((channel: Channel) => {
        let tmp: Channel = _self.listChannels.find((c) => {
          return c.channelId == channel.channelId;
        });

        if (tmp) {
          _self.listChannels.splice(_self.listChannels.indexOf(tmp), 1, channel);
        }
        _self.utils.showMessageToast("manage-channels.success.channel-info-updated", ToastTypes.SUCCESS);
        _self.selectChannelEditChannel(channel);
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    }
  }

  public createChannelSession(): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageChannelsService.createChannelSession(_self.selectedChannel.channel.channelId).then(() => {
      return _self.manageChannelsService.getChannelStreamingInfo(_self.selectedChannel.channel.channelId);
    }).then((data: ChannelInfo) => {
      _self.selectedChannel = data;
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public copyInputServer(): void {
    let _self = this;
    if (_self.selectedChannel && _self.selectedChannel.settings && _self.selectedChannel.settings.inputAServerRtmp) {
      _self.copyToClipboard(_self.selectedChannel.settings.inputAServerRtmp);
    }
  }

  public copyInputPassword(): void {
    let _self = this;
    if (_self.selectedChannel && _self.selectedChannel.settings && _self.selectedChannel.settings.inputASecretKey) {
      _self.copyToClipboard(_self.selectedChannel.settings.inputASecretKey);
    }
  }

  public copyToClipboard(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public updateChannelStatus(start: boolean): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageChannelsService.updateChannelStatus(_self.selectedChannel.channel.channelId, start).then(() => {
      return _self.manageChannelsService.getChannelStreamingInfo(_self.selectedChannel.channel.channelId);
    }).then((data: ChannelInfo) => {
      _self.selectedChannel = data;
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  /** MODIFICA CANALE **/

  public async checkChangeStatus(cancelAsyncToken: CancelAsyncToken) {
    let _self = this;
    while (true) {
      await _self.utils.waitMilliseconds(3000);
      let data: ChannelInfo;
      if (_self.selectedChannel && _self.selectedChannel.channel) {
        data = await _self.manageChannelsService.getChannelStreamingInfo(_self.selectedChannel.channel.channelId);
      }

      if (cancelAsyncToken.cancel) {
        break;
      }

      if (data && data.channel.status != _self.selectedChannel.channel.status) {
        _self.selectedChannel = data;
      }
    }
  }

  private search(text: string): Array<Channel> {
    let _self = this;
    return _self.channels.filter((channel: Channel) => {
      const term = text.toLowerCase();
      return channel.name.toLowerCase().includes(term)
        || channel.description.toLowerCase().includes(term)
        || channel.owner.forename.toLowerCase().includes(term)
        || channel.owner.surname.toLowerCase().includes(term)
        || (channel.owner.forename.toLowerCase() + " " + channel.owner.surname.toLowerCase()).includes(term);
    });
  }

  public refreshChannels(): void {
    let _self = this;
    let tmpChannels: Array<Channel | any> = _self.search(_self.filter.value);
    _self.collectionSize = tmpChannels.length;
    _self.listChannels = tmpChannels.slice((_self.page - 1) * _self.pageSize, (_self.page - 1) * _self.pageSize + _self.pageSize);
  }

}
