import { Injectable } from '@angular/core';
import { AuthService } from '../../Auth/auth.service';
import { HttpParams } from '@angular/common/http';
import { Channel, ChannelInfo, User } from 'src/app/Utils/common-classes';

@Injectable({
  providedIn: 'root'
})
export class ManageChannelsService {

  constructor(private authService: AuthService) { }

  public getChannelsAdmin(): Promise<Array<Channel>> {
    return this.authService.getRequest("/get-channels-admin", null, true);
  }

  public getChannelAdmin(channelId: string): Promise<ChannelInfo> {
    let params = new HttpParams();
    params = params.append('channelId', channelId);
    return this.authService.getRequest("/get-channel-admin", params, true);
  }

  public getChannelStreamingInfo(channelId: string): Promise<ChannelInfo> {
    let params = new HttpParams();
    params = params.append('channelId', channelId);
    return this.authService.getRequest("/get-channel-streaming-info", params, true);
  }

  public getChannelInvite(channelId: string, revoke?: boolean) {
    let params = new HttpParams();
    params = params.append('channelId', channelId);
    if (revoke) {
      params = params.append('revoke', 'revoke');
    }
    return this.authService.getRequest("/get-channel-invite", params, true);
  }

  public editChannel(channelId: string, name: string, description: string, duration: number): Promise<Channel> {
    const body = { channelId: channelId, name: name, description: description, duration: duration };
    return this.authService.postRequest("/edit-channel", body, true);
  }

  public updateChatUserRole(channelId: string, participants: Array<User>, moderator: boolean): Promise<void> {
    const body = { channelId: channelId, participants: participants, moderator: moderator };
    return this.authService.postRequest("/update-chat-user-role", body);
  }

  public updateChatUserAuthorizations(channelId: string, userIds: Array<string>, authorizations: Array<string>): Promise<void> {
    const body = { channelId: channelId, userIds: userIds, authorizations: authorizations };
    return this.authService.postRequest("/update-chat-user-role", body);
  }

  public createChannelSession(channelId: string): Promise<void> {
    const body = { channelId: channelId };
    return this.authService.postRequest("/create-channel-session", body);
  }

  public updateChannelStatus(channelId: string, start: boolean): Promise<void> {
    const body = { channelId: channelId, start: start };
    return this.authService.postRequest("/update-channel-status", body);
  }

  public createChannel(name: string, description: string, duration: number, participants: Array<User>): Promise<Channel> {
    const body = {
      name: name,
      description: description,
      duration: duration,
      participants: participants,
      userId: this.authService.getApplicationData().user.userId //todo modificare servizio prendere userId dalla sessione
    };
    return this.authService.postRequest("/create-channel", body, true);
  }

  public getUsers(): Promise<Array<User>> {
    return this.authService.getRequest("/get-active-users", null, true);
  }

  public setChannelParticipants(channelId: string, participants: Array<User>): Promise<void> {
    const body = {
      participants: participants,
      channelId: channelId,
      domainUrl: window.location.hostname
    };
    return this.authService.postRequest("/set-channel-participants", body);
  }

  public createUsers(channelId: string, users: Array<User>): Promise<void> {
    const body = {
      users: users,
      channelId: channelId,
      domainUrl: window.location.hostname
    };
    return this.authService.postRequest("/create-users", body);
  }
}
