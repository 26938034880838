import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {
  public isShow: boolean = false;
  public error: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public utils: UtilsService) {
  }

  ngOnInit() {
    if (!this.route.snapshot.params.token) {
      console.error("todo show error toast token vuoto");
      return;
    }
    let confirmRegistrationToken: string = this.route.snapshot.params.token;
    this.authService.confirmRegistration(confirmRegistrationToken).then(() => {
      //console.log("confirmed");
    }).catch((err) => {
      this.error = JSON.stringify(err);
      this.utils.getErrorShowToast(err);
    }).finally(() => {
      this.isShow = true;
    });
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }
}
