import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { v4 } from 'uuid/';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorCodes } from 'src/app/Utils/common-classes';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private bucketName: string;
  constructor() {
    this.bucketName = environment.bucket
  }

  bucket = new S3(
    {
      accessKeyId: 'AKIAXXFP2NGHRIVWOS5U',
      secretAccessKey: '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',
      region: 'eu-west-1'
    }
  );

  public uploadFile(file, folder: string, abortUploadSubject: Subject<void>, onProgress: (progress: S3.ManagedUpload.Progress) => void): Promise<S3.ManagedUpload.SendData> {
    let _self = this;
    return new Promise((resolve, reject) => {
      let abortSubscription = abortUploadSubject.subscribe(() => {
        upload.abort();
      });

      const contentType = file.type;

      const params = {
        Bucket: this.bucketName,
        Key: folder + v4(),
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
        ContentDisposition: 'attachment;'
      };
      let upload = _self.bucket.upload(params, (err: any, data: S3.ManagedUpload.SendData) => {
        abortSubscription.unsubscribe();
        if (err) {
          if (err.code == "RequestAbortedError") {
            reject(ErrorCodes.UPLOAD_ABORTED);
          } else {
            console.error('Errore nell upload del file: ', err);
            reject(err);
          }
        }
        resolve(data);
      });
      upload.on("httpUploadProgress", onProgress);
    });
  }

  public deleteFile(url: string): Promise<any> {
    let _self = this;
    /*
     valore 'Key' dei params è il path 'pathName1/pathName2/.../pathNameN/filename.ext'
     - path senza '/' all'inizio
  */
    return new Promise((resolve, reject) => {
      //dall' utl devo togliere la parte iniziale
      const params = {
        Bucket: this.bucketName,
        Key: url,
      };
      _self.bucket.deleteObject(params, function (err, data) {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
    });
  }

  public uploadBase64(base64, folder, abortUploadSubject: Subject<void>, onProgress: (progress: S3.ManagedUpload.Progress) => void): Promise<String> {
    let _self = this;
    let key: string = folder + v4();
    return new Promise((resolve, reject) => {
      try {
        let abortSubscription: Subscription = null;
        if (abortUploadSubject) {
          abortSubscription = abortUploadSubject.subscribe(() => {
            upload.abort();
          });
        }
        const base64Data: Buffer = new Buffer(base64.replace(/^data:image\/\w+;base64,/, ""), 'base64');
        const type = base64.split(';')[0].split('/')[1];

        const params = {
          Bucket: this.bucketName,
          Key: key,
          Body: base64Data,
          ACL: 'public-read',
          ContentEncoding: 'base64',
          ContentType: `image/${type}`
        }

        let upload = _self.bucket.putObject(params, (err, data: S3.PutObjectAclOutput) => {
          if (abortSubscription) {
            abortSubscription.unsubscribe();
          }
          if (err) {
            if (err.code == "RequestAbortedError") {
              reject(ErrorCodes.UPLOAD_ABORTED);
            } else {
              console.error('Errore nell upload del file: ', err);
              reject(err);
            }
          }
          resolve(key);
        });
        upload.on("httpUploadProgress", onProgress);
      } catch (err) {
        reject(err);
      }
    });
  }

}
