import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-lang-drop',
  templateUrl: './lang-drop.component.html',
  styleUrls: ['./lang-drop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LangDropComponent implements OnInit {
  public lang: string;

  constructor( public utils: UtilsService ) { 
    
  }

  ngOnInit() {
  }
  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

}
