// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /*production: false,
  apiUrl: 'http://192.168.30.157:3100',
  i18n: undefined*/

  production: true,
  apiUrl: 'https://im4stream.trizeta.com/services',
  //socketApiUrl: "https://aim4stream.com:3111",
  socketApiUrl: "https://test.adesuite.com:9443",
  i18n: "/assets/i18n/",
  agora: {
    appID: "64e94a2b85364e1dad338cdb95cb99c8"
  },
  bucket: "tz-repository",
  firebaseApi: {
    apiKey: "AIzaSyDe0nZhOHSJOa6S2liXZhxMhGRa1u5c-7s",
    authDomain: "im4stream.firebaseapp.com",
    projectId: "im4stream",
    storageBucket: "im4stream.appspot.com",
    messagingSenderId: "550703266622",
    appId: "1:550703266622:web:1fc73f0b26e16730934a6f",
    measurementId: "G-9YF6TCB0KC"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
