import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { ErrorCodes, PhoneSelectedCountry, SocialAuthMethods, ToastTypes } from 'src/app/Utils/common-classes';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  public formSubmitAttempt: boolean;
  private phoneSelectedCountry: PhoneSelectedCountry = { dialCode: "39", iso2: "it" }; //todo prendo da ip
  private returnUrl: string;
  private userInviteToken: string;
  private channelInviteCode: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService,
    private socialAuthService: SocialAuthService,
  ) { }

  ngOnInit() {
    this.userInviteToken = this.route.snapshot.params.userInviteToken;
    this.channelInviteCode = this.route.snapshot.params.channelInviteCode;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/home';
    this.form = this.fb.group({
      email: ['', this.customEmailValidator],
      phone: [''],
      username: ['', Validators.required],
      forename: ['', Validators.required],
      surname: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['']
    });

    if (this.authService.getAccessToken() && this.authService.getApplicationData()) {
      this.router.navigate([this.returnUrl]);
    }
  }

  public hasError(event) {
    console.log("hasError", event);
  }

  public getNumber(event) {
    console.log("getNumber", event);
  }

  public telInputObject(event) {
    console.log("telInputObject", event);
  }

  public onCountryChange(event: any) {
    this.phoneSelectedCountry = event;
  }

  async onSubmit() {
    let _self = this;
    _self.formSubmitAttempt = false;

    const email: string = _self.form.get("email").value;
    const username: string = _self.form.get('username').value;
    const forename: string = _self.form.get('forename').value;
    const surname: string = _self.form.get('surname').value;
    const password: string = _self.form.get('password').value;
    const confirmPassword: string = _self.form.get("confirmPassword").value;
    const phoneNumber: string = _self.form.get("phone").value;
    const phoneCountry: string = _self.phoneSelectedCountry.iso2;

    console.log("phoneSelectedCountry", phoneCountry);
    console.log("phone", phoneNumber);

    if (password != confirmPassword) {
      _self.utils.showMessageToast("register.error.invalid-confirm-password", ToastTypes.ERROR);
      return;
    }

    if (_self.form.valid) {
      _self.authService.register(email, username, forename, surname, password, phoneNumber, phoneCountry, _self.userInviteToken, _self.channelInviteCode).then(() => {
        _self.utils.showMessageToast("register.success.register-success", ToastTypes.SUCCESS);
        _self.router.navigate(['login']);
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.utils.showMessageToast("register.error.invalid-form", ToastTypes.ERROR);
    }
  }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

  public signUpWithGoogle(): void {
    let _self = this;
    _self.utils.showShortLoader();
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data: SocialUser) => {
      return _self.authService.loginSocial(data.idToken, SocialAuthMethods.GOOGLE, _self.userInviteToken);
    }).then((data) => {
      _self.router.navigate(['home']);
    }).catch((err) => {
      if (err && err.error == "popup_closed_by_user") {
        return Promise.resolve();
      }

      if (err && err.error == ErrorCodes.USER_REQUEST_PENDING) {
        _self.utils.showMessageToast("register.success.register-success", ToastTypes.SUCCESS);
        _self.router.navigate(['login']);
        return Promise.resolve();
      }
      return _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public signUpWithFacebook(): void {
    let _self = this;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: SocialUser) => {
      return _self.authService.loginSocial(data.authToken, SocialAuthMethods.FACEBOOK, _self.userInviteToken);
    }).then((data) => {
      _self.router.navigate(['home']);
    }).catch((err) => {
      if (err == "User cancelled login or did not fully authorize.") {
        return Promise.resolve();
      }
      if (err && err.error == ErrorCodes.USER_REQUEST_PENDING) {
        _self.utils.showMessageToast("register.success.register-success", ToastTypes.SUCCESS);
        _self.router.navigate(['login']);
        return Promise.resolve();
      }
      return _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  private customEmailValidator(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return Validators.email(control);
  }
}
