import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { Channel } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  constructor(private router: Router,
    private authService: AuthService) {
    if (this.authService.getAccessToken() && this.authService.getApplicationData()) {
      if (this.authService.getApplicationData().user.tempUserCode && this.authService.getApplicationData().channelId) {
        this.router.navigate(['channel/' + this.authService.getApplicationData().channelId]);
      }
    }
  }

  ngOnInit() {
  }
}
