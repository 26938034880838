import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileHandle } from 'src/app/Directives/Drag/drag.directive';
import { ManageChannelsService } from 'src/app/Services/Admin/ManageChannels/manage-channels.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ToastTypes, User } from 'src/app/Utils/common-classes';
import { Utils } from 'src/app/Utils/utils';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.scss']
})
export class CreateUsersComponent implements OnInit {

  @Input() channelId: string;
  public folderId: string;
  public users: Array<User> = [];

  constructor(private activeModal: NgbActiveModal,
    private cdRef: ChangeDetectorRef,
    private utils: UtilsService,
    private manageChannelsService: ManageChannelsService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  handleChange() {
  }

  public closeDialog(): void {
    this.activeModal.close('Close click');//evento chiusura modale
  };

  public onFileChanged(event) {
    let _self = this;
    if (event.target.files && event.target.files.length > 0) {
      const file: File = event.target.files[0];
      if (file.name.match(/(.xls|.xlsx)/)) {
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];
          const parsedDocument: Array<Array<string>> = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });
          if (parsedDocument && parsedDocument.length) {
            //salto la prima riga perchè contiene l'header
            for (let i = 1; i < parsedDocument.length; i++) {
              let user = new User();
              user.forename = parsedDocument[i][0];
              user.surname = parsedDocument[i][1];
              user.email = parsedDocument[i][2];
              _self.users.push(user);
            }
          }
        };

        reader.onerror = (e: any) => {
          console.error(e);
          //todo errore
        }

        reader.readAsBinaryString(file);
      }
      console.log(file);
    }
  }

  public filesDropped(files: FileHandle[]): void {
    let _self = this;
    if (files && files.length > 0) {
      for (let fl of files) {
        const file: File = fl.file;
        if (file.name.match(/(.xls|.xlsx)/)) {
          const reader: FileReader = new FileReader();
          reader.onload = (e: any) => {
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            const parsedDocument: Array<Array<string>> = XLSX.utils.sheet_to_json(ws, { raw: true, header: 1 });
            if (parsedDocument && parsedDocument.length) {
              //salto la prima riga perchè contiene l'header
              for (let i = 1; i < parsedDocument.length; i++) {
                let user = new User();
                user.forename = parsedDocument[i][0];
                user.surname = parsedDocument[i][1];
                user.email = parsedDocument[i][2];
                _self.users.push(user);
              }
            }
          };

          reader.onerror = (e: any) => {
            console.error(e);
            //todo errore
          }

          reader.readAsBinaryString(file);
        }
        console.log(file);
      }

    }
  }

  public deleteRow(user: User) {
    this.users = this.users.filter((u: User) => {
      return u != user;
    });
  }

  public submit() {
    if (!this.validateData()) {
      return;
    }
    this.utils.showShortLoader();
    this.manageChannelsService.createUsers(this.channelId, this.users).then((data) => {
      console.log(data);
      //todo se ho delle righe non valide le mostro su una seconda tabella
      this.utils.showMessageToast("general.success.operation-complete", ToastTypes.SUCCESS);
      this.activeModal.close('success');//evento chiusura modale
    }).catch((err) => {
      this.utils.getErrorShowToast(err);
    }).finally(() => {
      this.utils.hideShortLoader();
    });
  }

  public addRow() {
    this.users.push(new User());
  }

  public validateData(): boolean {
    let invalid = false;
    for (let i = 0; i < this.users.length; i++) {
      if (!this.validateUser(this.users[i])) {
        invalid = true;
      }
    }

    this.cdRef.detectChanges();

    return !invalid;
  }

  public validateUser(user: User): boolean {
    if (!user) {
      return false;
    }

    if (!user.forename || !user.surname || !user.email) {
      user.error = this.utils.translateKeySync("dialog.create-users-component.error.invalid-data");
      return false;
    }

    if (!Utils.validateEmail(user.email)) {
      user.error = this.utils.translateKeySync("dialog.create-users-component.error.invalid-email");
      return false;
    }

    user.error = null;
    return true;
  }
}
