import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageComponent implements OnInit {
  public lang: string;

  constructor(public utils: UtilsService) {

  }

  ngOnInit() {
  }
  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

}