import { Injectable, TemplateRef } from '@angular/core';
import { StorageKeys, Error, ErrorCodes, ToastTypes } from 'src/app/Utils/common-classes';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../Auth/auth.service';
import { Router } from '@angular/router';
import { ToastService } from '../Toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private language: string;
  private loading: boolean = false;
  private dialogUnclosable: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private toastService: ToastService) {
    let language = localStorage.getItem(StorageKeys.LANGUAGE);
    if (!language) {
      //todo prendo dalla lingua del sistema
      language = "it";
    }
    this.setLanguage(language);
  }

  public showShortLoader(): void {
    this.loading = true;
    document.body.classList.add('loading');
  }

  public hideShortLoader(): void {
    this.loading = false;
    document.body.classList.remove('loading');
  }

  public setDialogUnclosable(dialogUnclosable: boolean): void {
    this.dialogUnclosable = dialogUnclosable;
  }

  public waitMilliseconds(ms): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public translateKey(key: string): Promise<string> {
    let _self = this;
    return new Promise<string>((resolve, reject) => {
      _self.translate.get(key).subscribe((value: string) => {
        resolve(value)
      });
    });
  }

  public translateKeySync(key: string): string {
    return this.translate.instant(key);
  }

  public translateKeyWithData(key: string, data: Object): Promise<string> {
    let _self = this;
    return new Promise<string>((resolve, reject) => {
      _self.translate.get(key, data).subscribe((value: string) => {
        resolve(value)
      });
    });
  }

  private getErrorKey(error: string): string {
    let errorKey: string = "general.error.unknown-error";
    switch (error) {
      case ErrorCodes.INVALID_PATH:
        errorKey = "general.error.invalid-path";
        break;
      case ErrorCodes.MISSING_REQUIRED_DATA:
        errorKey = "general.error.missing-required-data";
        break;
      case ErrorCodes.INVALID_SERVICE:
        errorKey = "general.error.invalid-service";
        break;
      case ErrorCodes.INVALID_FOLDER_NAME:
        errorKey = "general.error.invalid-folder-name";
        break;
      case ErrorCodes.DUPLICATE_FILE_NAME:
        errorKey = "general.error.duplicate-file-name";
        break;
      case ErrorCodes.DUPLICATE_FOLDER_NAME:
        errorKey = "general.error.duplicate-folder-name";
        break;
      case ErrorCodes.PARENT_FOLDER_NOT_FOUND:
        errorKey = "general.error.parent-folder-not-found";
        break;
      case ErrorCodes.DESTINATION_IS_SUBFOLDER:
        errorKey = "general.error.destination-is-subfolder";
        break;
      case ErrorCodes.USER_NOT_FOUND:
        errorKey = "general.error.user-not-found";
        break;
      case ErrorCodes.USER_NOT_AUTHORIZED:
        errorKey = "general.error.user-not-authorized";
        break;
      case ErrorCodes.USER_REQUEST_PENDING:
        errorKey = "general.error.user-request-pending";
        break;
      case ErrorCodes.INVALID_RECOVERY_CODE:
        errorKey = "general.error.invalid-recovery-code";
        break;
      case ErrorCodes.INVALID_PASSWORD:
        errorKey = "general.error.invalid-password";
        break;
      case ErrorCodes.FILE_NOT_FOUND:
        errorKey = "general.error.file-not-found";
        break;
      case ErrorCodes.FOLDER_NOT_FOUND:
        errorKey = "general.error.folder-not-found";
        break;
      case ErrorCodes.TREE_NOT_FOUND:
        errorKey = "general.error.tree-not-found";
        break;
      case ErrorCodes.SESSION_NOT_FOUND:
        errorKey = "general.error.session-not-found";
        break;
      case ErrorCodes.DUPLICATE_TREE_NAME:
        errorKey = "general.error.duplicate-tree-name";
        break;
      case ErrorCodes.UNKNOWN_ERROR:
        errorKey = "general.error.unknown-error";
        break;
      case ErrorCodes.CONNECTION_REFUSED:
        errorKey = "general.error.connection-refused";
        break;
      case ErrorCodes.LOAD_PREVIEW_ERROR:
        errorKey = "general.error.load-preview-error";
        break;
      case ErrorCodes.OBJECT_ALREADY_EXISTS:
        errorKey = "general.error.object-already-exists";
        break;
      case ErrorCodes.CHANNEL_NOT_FOUND:
        errorKey = "general.error.channel-not-found";
        break;
      case ErrorCodes.USER_EMAIL_NOT_VERIFIED:
        errorKey = "general.error.user_email_not_verified";
        break;
      case ErrorCodes.USER_PHONE_NOT_VERIFIED:
        errorKey = "general.error.user_phone_not_verified";
        break;
      case ErrorCodes.INVALID_EMAIL_VERIFICATION_CODE:
        errorKey = "general.error.invalid_email_verification_code";
        break;
      case ErrorCodes.INVALID_PHONE_VERIFICATION_CODE:
        errorKey = "general.error.invalid_phone_verification_code";
        break;
      case ErrorCodes.USER_EMAIL_ALREADY_VERIFIED:
        errorKey = "general.error.user_email_already_verified";
        break;
      case ErrorCodes.USER_PHONE_ALREADY_VERIFIED:
        errorKey = "general.error.user_phone_already_verified";
        break;
      case ErrorCodes.INVITE_NOT_FOUND:
        errorKey = "general.error.invite_not_found";
        break;
    }
    return errorKey;
  }

  public getError(error: any): Promise<string> {
    let _self = this;
    return new Promise<string>((resolve, reject) => {
      let errorKey: string = "general.error.unknown-error";
      let errorString: string = UtilsService.getErrorString(error);
      if (error) {
        if (error.status == 0) {
          errorKey = _self.getErrorKey(ErrorCodes.CONNECTION_REFUSED);
          _self.translateKey(errorKey).then((translatedKey: string) => {
            resolve(translatedKey);
          });
        } else {
          if (errorString == ErrorCodes.SESSION_NOT_FOUND) {
            _self.authService.logoutSync();
            errorKey = _self.getErrorKey(errorString);
            _self.router.navigate(['login']).then(() => {
              _self.showMessageToast(errorKey, ToastTypes.ERROR);
              resolve(null);
            });
          } else {
            errorKey = _self.getErrorKey(errorString);
            _self.translateKey(errorKey).then((translatedKey: string) => {
              resolve(translatedKey);
            });
          }
        }
      } else {
        _self.translateKey(errorKey).then((translatedKey: string) => {
          resolve(translatedKey);
        });
      }
    });
  }

  public static getErrorString(errorObject): string {
    if (!errorObject) {
      return ErrorCodes.UNKNOWN_ERROR;
    }

    if (typeof errorObject == "string") {
      return errorObject;
    }

    return this.getErrorString(errorObject.error);
  }

  public getLanguage(): string {
    return this.language;
  }

  public setLanguage(language: string): void {
    let _self = this;
    _self.language = language;
    localStorage.setItem(StorageKeys.LANGUAGE, language);
    _self.translate.use(language);
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public isDialogUnclosable(): boolean {
    return this.dialogUnclosable;
  }

  public showTemplateToast(templateRef: TemplateRef<any>, type?: string): void {
    switch (type) {
      case ToastTypes.SUCCESS:
        this.toastService.show(templateRef, { classname: 'bg-success text-light', delay: 10000 });
        break;
      case ToastTypes.ERROR:
        this.toastService.show(templateRef, { classname: 'bg-danger text-light', delay: 15000 });
        break;
      default:
        this.toastService.show(templateRef);
        break;
    }
  }

  public showMessageToast(key: string, type?: string): void {
    let _self = this;
    _self.translateKey(key).then((message: string) => {
      switch (type) {
        case ToastTypes.SUCCESS:
          this.toastService.show(message, { classname: 'bg-success text-light' });
          break;
        case ToastTypes.ERROR:
          this.toastService.show(message, { classname: 'bg-danger text-light' });
          break;
        default:
          this.toastService.show(message);
          break;
      }
    });
  }

  public getErrorShowToast(error: any): Promise<void> {
    let _self = this;
    return new Promise<void>((resolve, reject) => {
      _self.getError(error).then((translatedError: string) => {
        if (translatedError) {
          _self.showMessageToast(translatedError, ToastTypes.ERROR);
        }
        resolve();
      })
    });
  }
}
