import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdminService implements CanActivate {

  constructor(public authService: AuthService, public router: Router) { }

  async canActivate() {

    if (this.authService.isPlatformAdmin()) {
      return true;
    }
    await this.router.navigate(['home']);
    return false;
  }
}
