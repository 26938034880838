import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-facebook-sign-in-button',
  templateUrl: './facebook-sign-in-button.component.html',
  styleUrls: ['./facebook-sign-in-button.component.scss']
})
export class FacebookSignInButtonComponent {

  @Output() buttonClick = new EventEmitter();
  constructor() { }

  public onClick(event: Event) {
    this.buttonClick.emit(event);
  }


}
