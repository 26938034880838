import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {

  constructor(
    public utils: UtilsService,
    public fontAwesomeService: FontAwesomeService
  ) { }

  ngOnInit() {
  }
  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }

}
