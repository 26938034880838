import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocketEmitterService {
  private socket: SocketIOClient.Socket;

  constructor() { }


  public initSocket(socket: SocketIOClient.Socket): void {
    this.socket = socket;
  }

  public sendChatMessage(msg: string): void {
    this.socket.emit("chat-message-request", msg);
  };

  public sendAcceptChatMessage(chatMessageId: string): void {
    this.socket.emit("accept-message", chatMessageId);
  }

  public sendRejectChatMessage(chatMessageId: string): void {
    this.socket.emit("reject-message", chatMessageId);
  }
}
