import { Component, ViewEncapsulation } from '@angular/core';
import { UtilsService } from './Services/Utils/utils.service';
import { Router } from '@angular/router';
import { AuthService } from './Services/Auth/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  title = 'Im4streaming';  

  constructor(public router: Router,
    public authService: AuthService,
    public utils: UtilsService) { }

  // public logout(): void {
  //   let _self = this;
  //   _self.authService.logout().then(() => {
  //     _self.router.navigate(['login']);
  //   });
  // }

  public changeLanguage(lang: string): void {
    this.utils.setLanguage(lang);
  }
}
