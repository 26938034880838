import { Injectable } from '@angular/core';
import { StorageKeys, ErrorCodes, LoginPayload, ApplicationDataPayload, UserAuthorization, UserAuthorizationCodes } from 'src/app/Utils/common-classes';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private accessToken: string;
  private platformAdmin: boolean;
  private applicationData: ApplicationDataPayload;

  constructor(private httpClient: HttpClient) {
    this.accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    this.applicationData = JSON.parse(localStorage.getItem(StorageKeys.APPLICATION_DATA));
    this.setPlatformAdmin();
  }

  public getAccessToken(): string {
    return this.accessToken;
  }

  public setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem(StorageKeys.ACCESS_TOKEN, token);
  }

  public getApplicationData(): ApplicationDataPayload {
    return this.applicationData;
  }

  public setApplicationData(applicationData: ApplicationDataPayload): void {
    this.applicationData = applicationData;
    localStorage.setItem(StorageKeys.APPLICATION_DATA, JSON.stringify(applicationData));
    this.setPlatformAdmin();
  }

  private getHeaders(): HttpHeaders {
    let _self = this;
    if (_self.accessToken) {
      return new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8', 'Authorization': 'Bearer ' + this.accessToken });
    } else {
      return new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    }
  }

  public register(email: string, username: string, forename: string, surname: string, password: string, phoneNumber: string, phoneCountry: string, userInviteToken: string, channelInviteCode: string): Promise<void> {
    const body = {
      email: email,
      username: username,
      forename: forename,
      surname: surname,
      password: password,
      domainUrl: window.location.hostname,
      userInviteToken: userInviteToken,
      channelInviteCode: channelInviteCode,
      cid: email,
      userOtherData: {
        phoneNumber: phoneNumber,
        phoneCountry: phoneCountry
      }
    };
    return this.postRequest("/register", body);
  }

  public login(username: string, password: string): Promise<void> {
    let _self = this;
    return new Promise<void>((resolve, reject) => {
      const body = { email: username, password: password, domainUrl: window.location.hostname };
      _self.postRequest("/login", body).then((data: LoginPayload) => {
        if (data && data.accessToken && data.applicationData) {
          _self.setAccessToken(data.accessToken);
          _self.setApplicationData(data.applicationData);
          resolve();
        } else {
          reject(new Error(ErrorCodes.UNKNOWN_ERROR));
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public loginSocial(token: string, socialAuth: string, userInviteToken: string): Promise<void> {
    let _self = this;
    return new Promise<void>((resolve, reject) => {
      const body = { token: token, domainUrl: window.location.hostname, socialAuth: socialAuth, userInviteToken: userInviteToken };
      _self.postRequest("/social-login", body).then((data: LoginPayload) => {
        if (data && data.accessToken && data.applicationData) {
          _self.setAccessToken(data.accessToken);
          _self.setApplicationData(data.applicationData);
          resolve();
        } else {
          reject(new Error(ErrorCodes.UNKNOWN_ERROR));
        }
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public logout(): Promise<void> {
    let _self = this;
    return new Promise<void>((resolve, reject) => {
      new Promise<void>((innResolve, innReject) => {
        this.postRequest("/logout", null).then(() => {
          innResolve();
        }).catch((error) => {
          innReject(error);
        });
      }).then(() => {
        return Promise.resolve();
      }).catch((err) => {
        console.error(err);
        return Promise.resolve();
      }).then(() => {
        _self.removeAccessToken();
        _self.removeApplicationData();
        resolve();
      });
    });
  }
  /**
   * metodo che elimina dal localstorage i dati di accesso
   */
  public logoutSync(): void {
    this.removeAccessToken();
    this.removeApplicationData();
  }

  public forgotPassword(email: string): Promise<void> {
    const body = { email: email, domainUrl: window.location.hostname };
    return this.postRequest("/forgot-password", body);
  }

  public resetPassword(password: string, resetPasswordToken: string): Promise<void> {
    const body = { password: password, resetPasswordToken: resetPasswordToken, domainUrl: window.location.hostname };
    return this.postRequest("/reset-password", body);
  }

  public confirmRegistration(confirmRegistrationToken: string): Promise<void> {
    const body = { confirmRegistrationToken: confirmRegistrationToken, domainUrl: window.location.hostname };
    return this.postRequest("/confirm-registration", body);
  }

  private removeAccessToken(): void {
    this.accessToken = null;
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
  }

  private removeApplicationData(): void {
    this.accessToken = null;
    localStorage.removeItem(StorageKeys.APPLICATION_DATA);
  }

  private setPlatformAdmin(): void {
    let _self = this;

    if (_self.applicationData &&
      _self.applicationData.user &&
      _self.applicationData.user.authorizations) {
      let admin: boolean = false;
      for (let i = 0; i < _self.applicationData.user.authorizations.length; i++) {
        const element = _self.applicationData.user.authorizations[i];
        if (element.authorizationCode == UserAuthorizationCodes.ADMIN) {
          admin = true;
          break;
        }
      }
      _self.platformAdmin = admin;
    }
  }

  public isPlatformAdmin(): boolean {
    return this.platformAdmin;
  }

  /**
   * Chiamata in post al server Im4Stream
   * @param service path del servizio con '/' iniziale
   * @param body oggetto da inserire nel body della richiesta
   * @param cannotBeNull se true esegue il reject della promessa quando la risposta è null o undefined
   * @returns 
   */
  public postRequest(service: string, body: any, cannotBeNull?: boolean): Promise<any> {
    let _self = this;
    return new Promise((resolve, reject) => {
      const headers = _self.getHeaders();
      _self.httpClient.post(environment.apiUrl + service, body, { headers: headers }).subscribe((data) => {
        if (cannotBeNull && !data) {
          return reject(ErrorCodes.UNKNOWN_ERROR);
        }
        resolve(data);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  /**
   * Chiamata in get al server Im4Stream
   * @param service path del servizio con '/' iniziale
   * @param params parametri della query
   * @param cannotBeNull se true esegue il reject della promessa quando la risposta è null o undefined
   * @returns 
   */
  public getRequest(service: string, params: HttpParams, cannotBeNull?: boolean): Promise<any> {
    let _self = this;
    if (!params) {
      params = new HttpParams();
    }
    return new Promise((resolve, reject) => {
      const headers = _self.getHeaders();
      _self.httpClient.get(environment.apiUrl + service, { headers: headers, params: params }).subscribe((data) => {
        if (cannotBeNull && !data) {
          return reject(ErrorCodes.UNKNOWN_ERROR);
        }
        resolve(data);
      }, (error: any) => {
        reject(error);
      });
    });
  }

}
