import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AuthService } from '../Auth/auth.service';
import { ChannelInfo, Channel, ChannelAttachmentMediaFile, JoinChannelPayload } from 'src/app/Utils/common-classes';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor(private authService: AuthService) { }

  public getChannel(channelId: string): Promise<ChannelInfo> {
    let params = new HttpParams();
    params = params.append('channelId', channelId);
    return this.authService.getRequest("/get-channel", params, true);
  }

  public getChannels(): Promise<Array<Channel>> {
    return this.authService.getRequest("/get-channels", null, true);
  }

  public addChannelAttachment(channelAttachmentUpload: ChannelAttachmentMediaFile): Promise<void> {
    return this.authService.postRequest("/add-channel-attachment", channelAttachmentUpload);
  }

  public getChannelAttachments(channelId: string): Promise<Array<ChannelAttachmentMediaFile>> {
    let params = new HttpParams();
    params = params.append('channelId', channelId);
    return this.authService.getRequest("/get-channels", params, true);
  }

  public joinChannel(inviteCode: string, forename?: string, surname?: string, email?: string): Promise<JoinChannelPayload> {
    const body = {
      inviteCode: inviteCode,
      forename: forename,
      surname: surname,
      email: email,
      domainUrl: window.location.hostname
    }
    return this.authService.postRequest("/join-channel", body);
  }
}
