import { Injectable } from '@angular/core';
import { faExclamationTriangle, faAngleDoubleLeft, faHome, faPlayCircle, faTv, faVideo, faUser, faLongArrowAltRight, faShieldAlt, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';


@Injectable({
  providedIn: 'root'
})
export class FontAwesomeService {

  public  faExclamationTriangle = faExclamationTriangle;
  public  faAngleRight = faAngleRight;
  public  faAngleLeft = faAngleLeft;
  public  faAngleDown = faAngleDown;
  public  faAngleUp = faAngleUp;
  public  faAngleDoubleLeft = faAngleDoubleLeft;
  public  faHome = faHome;
  public  faPlayCircle = faPlayCircle;
  public  faTv = faTv;
  public  faVideo = faVideo;
  public  faUser = faUser;
  public  faLongArrowAltRight = faLongArrowAltRight;
  public  faShieldAlt = faShieldAlt;
  public  faExpand = faExpand;
  public  faCompress = faCompress;
  

  constructor() { }
}
