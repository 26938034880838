import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User, Channel, ToastTypes } from 'src/app/Utils/common-classes';
import { ManageChannelsService } from 'src/app/Services/Admin/ManageChannels/manage-channels.service';
import { AuthService } from 'src/app/Services/Auth/auth.service';
import { FontAwesomeService } from 'src/app/Services/FontAwesome/font-awesome.service';
import { ToastService } from 'src/app/Services/Toast/toast.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-create-channel',
  templateUrl: './create-channel.component.html',
  styleUrls: ['./create-channel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateChannelComponent implements OnInit {
  public createChannelForm: FormGroup;
  public addedUsersSelectedAction: number = 0;
  public platformUsersSelectedAction: number = 0;
  public addedUsers: Array<User>;
  public platformUsers: Array<User>;
  public selectedAddedUsers: Array<User>;
  public selectedPlatformUsers: Array<User>;

  constructor(
    private fb: FormBuilder,
    private manageChannelsService: ManageChannelsService,
    private authService: AuthService,
    public fontAwesomeService: FontAwesomeService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageChannelsService.getUsers().then((data: Array<User>) => {
      _self.addedUsers = [];
      _self.selectedAddedUsers = [];
      _self.selectedPlatformUsers = [];
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let element: User = data[i];
          if (element.userId == _self.authService.getApplicationData().user.userId) {
            element.owner = true;
            element.moderator = true;
            _self.addedUsers.push(element);
            data.splice(i, 1);
            break;
          }
        }
        _self.platformUsers = data;
      }
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });

    _self.createChannelForm = _self.fb.group({
      channelName: ['', Validators.required],
      channelDescription: ['', null],
      channelDuration: ['', Validators.required]
    });
  }

  public createChannel(): void {
    let _self = this;
    const CHANNEL_NAME: string = _self.createChannelForm.get('channelName').value;
    const CHANNEL_DESCRIPTION: string = _self.createChannelForm.get('channelDescription').value;
    const CHANNEL_DURATION: number = _self.createChannelForm.get('channelDuration').value;
    if (_self.createChannelForm.valid) {
      _self.utils.showShortLoader();
      _self.manageChannelsService.createChannel(CHANNEL_NAME, CHANNEL_DESCRIPTION, CHANNEL_DURATION, _self.addedUsers).then((channel: Channel) => {
        _self.utils.showMessageToast("create-channel.success.channel-created", ToastTypes.SUCCESS);
        _self.init();
      }).catch((err) => {
        _self.utils.getErrorShowToast(err);
      }).finally(() => {
        _self.utils.hideShortLoader();
      });
    } else {
      _self.utils.showMessageToast("create-channel.error.invalid-form", ToastTypes.ERROR);
    }
  }

  public addedUsersAction(): void {
    let _self = this;
    switch (_self.addedUsersSelectedAction) {
      case 1:
        _self.changeAddedUsersStatus(true);
        break;
      case 2:
        _self.changeAddedUsersStatus(false);
        break;
      case 5:
        _self.removeAddedUsers();
        break;
      default:
        break;
    }
    setTimeout(() => {
      _self.addedUsersSelectedAction = 0;
    }, 1);
  }

  public onAddedUsersCheckToggle(): void {
    let _self = this;
    _self.selectedAddedUsers = _self.addedUsers.filter((user: User) => {
      return user.checked;
    });
  }

  private changeAddedUsersStatus(moderator: boolean): void {
    let _self = this;
    for (let i = 0; i < _self.selectedAddedUsers.length; i++) {
      let addedUserIndex: number = _self.addedUsers.indexOf(_self.selectedAddedUsers[i]);
      if (addedUserIndex > -1) {
        _self.addedUsers[addedUserIndex].moderator = moderator;
        _self.addedUsers[addedUserIndex].checked = false;
      }
    }
    _self.selectedAddedUsers = [];
  }

  private removeAddedUsers(): void {
    let _self = this;
    for (let i = 0; i < _self.selectedAddedUsers.length; i++) {
      let addedUserIndex: number = _self.addedUsers.indexOf(_self.selectedAddedUsers[i]);
      if (addedUserIndex > -1) {
        _self.addedUsers.splice(addedUserIndex, 1);
      }
      _self.selectedAddedUsers[i].moderator = false;
      _self.selectedAddedUsers[i].checked = false;
    }

    _self.platformUsers = _self.platformUsers.concat(_self.selectedAddedUsers);
    _self.selectedAddedUsers = [];
  }

  public platformUsersAction(): void {
    let _self = this;
    switch (_self.platformUsersSelectedAction) {
      case 1:
        _self.addPlatformUsers(false);
        break;
      case 2:
        _self.addPlatformUsers(true);
        break;
      default:
        break;
    }
    setTimeout(() => {
      _self.platformUsersSelectedAction = 0;
    }, 1);
  }

  public onPlatformUsersCheckToggle(): void {
    let _self = this;
    _self.selectedPlatformUsers = _self.platformUsers.filter((user: User) => {
      return user.checked;
    });
  }

  private addPlatformUsers(moderator: boolean): void {
    let _self = this;
    for (let i = 0; i < _self.selectedPlatformUsers.length; i++) {
      let platformUserIndex: number = _self.platformUsers.indexOf(_self.selectedPlatformUsers[i]);
      if (platformUserIndex > -1) {
        _self.platformUsers.splice(platformUserIndex, 1);
      }
      _self.selectedPlatformUsers[i].moderator = moderator;
      _self.selectedPlatformUsers[i].checked = false;
    }

    _self.addedUsers = _self.addedUsers.concat(_self.selectedPlatformUsers);
    _self.selectedPlatformUsers = [];
  }

}
