import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoPlayerComponent } from './Components/video-player/video-player.component';
import { ChannelComponent } from './Pages/channel/channel.component';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoginComponent } from './Pages/login/login.component';
import { HomeComponent } from './Pages/home/home.component';
import { RegisterComponent } from './Pages/register/register.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2TelInputModule } from 'ng2-tel-input';
//Translate service
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountComponent } from './Pages/account/account.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThankYouComponent } from './Pages/thank-you/thank-you.component';
import { ForgotYourPasswordComponent } from './Pages/forgot-your-password/forgot-your-password.component';
import { ManageChannelsComponent } from './Pages/manage-channels/manage-channels.component';
import { SidebarComponent } from './Components/sidebar/sidebar.component';
import { LangDropComponent } from './Components/lang-drop/lang-drop.component';
import { ChannelsCarouselsComponent } from './Components/channels-carousels/channels-carousels.component';
import { CreateChannelComponent } from './Pages/create-channel/create-channel.component';
import { OfflineChannelsComponent } from './Pages/offline-channels/offline-channels.component';
import { OnlineChannelsComponent } from './Pages/online-channels/online-channels.component';
import { ManageUsersComponent } from './Pages/manage-users/manage-users.component';
import { ToastContainerComponent } from './Components/toast-container/toast-container.component';
import { ChangePasswordComponent } from './Pages/change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { LanguageComponent } from './Pages/language/language.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxAgoraModule } from 'ngx-agora';
import { UploadAttachmentComponent } from './Components/upload-attachment/upload-attachment.component';
import { DragDirective } from './Directives/Drag/drag.directive';
import { NgVarDirective } from './Directives/ngVar/ng-var.directive';
import { ChannelAttachmentsComponent } from './Components/channel-attachments/channel-attachments.component';
import { ConfirmRegistrationComponent } from './Pages/confirm-registration/confirm-registration.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { GoogleSignInButtonComponent } from './Components/social/google-sign-in-button/google-sign-in-button.component';
import { FacebookSignInButtonComponent } from './Components/social/facebook-sign-in-button/facebook-sign-in-button.component';
import { CreateUsersComponent } from './Components/create-users/create-users.component';
import { JoinChannelComponent } from './Pages/join-channel/join-channel.component';
import { InviteUsersComponent } from './Components/invite-users/invite-users.component';


export function httpLoaderFactory(httpClient: HttpClient) {
  //prende il path di default assets/i18n/lang-code.json
  // return new TranslateHttpLoader(httpClient);

  return new TranslateHttpLoader(httpClient, environment.i18n);
}

@NgModule({
  entryComponents: [UploadAttachmentComponent, ChannelAttachmentsComponent, CreateUsersComponent, InviteUsersComponent],
  declarations: [
    AppComponent,
    VideoPlayerComponent,
    ChannelComponent,
    NotFoundComponent,
    LoginComponent,
    HomeComponent,
    RegisterComponent,
    AccountComponent,
    ThankYouComponent,
    ForgotYourPasswordComponent,
    ManageChannelsComponent,
    SidebarComponent,
    LangDropComponent,
    ChannelsCarouselsComponent,
    CreateChannelComponent,
    OfflineChannelsComponent,
    OnlineChannelsComponent,
    ManageUsersComponent,
    ToastContainerComponent,
    ChangePasswordComponent,
    LanguageComponent,
    UploadAttachmentComponent,
    DragDirective,
    NgVarDirective,
    ChannelAttachmentsComponent,
    ConfirmRegistrationComponent,
    GoogleSignInButtonComponent,
    FacebookSignInButtonComponent,
    CreateUsersComponent,
    JoinChannelComponent,
    InviteUsersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxAgoraModule.forRoot({ AppID: environment.agora.appID }),
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    DeviceDetectorModule,
    Ng2TelInputModule,
    AngularFireModule.initializeApp(environment.firebaseApi),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SocialLoginModule
  ], providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '550703266622-iskrnv554c97vp672gcbosv7ipo24g77.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1258657294519066')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }