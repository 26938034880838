import { Component, Input, OnInit } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { FileHandle } from 'src/app/Directives/Drag/drag.directive';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { UploadService } from 'src/app/Services/Upload/upload.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ChannelAttachmentMediaFile, ErrorCodes, ToastTypes } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-upload-attachment',
  templateUrl: './upload-attachment.component.html',
  styleUrls: ['./upload-attachment.component.scss']
})
export class UploadAttachmentComponent implements OnInit {
  @Input() channelId: string;
  public folderId: string;
  public arrayFiles: Array<any> = [];
  public selectedFiles: Array<any> = [];
  public uploading: boolean = false;
  public uploadingPreview: boolean = false;
  public aborted: boolean = false;
  public currentFile: number;
  public totalFiles: number;
  public dateFromModel: NgbDateStruct;
  public dateToModel: NgbDateStruct;
  public currentUploadProgress: number = 0;
  private abortUploadSubject: Subject<void>;
  private lastUpdate: number = new Date().getTime();

  constructor(private activeModal: NgbActiveModal,
    private utils: UtilsService,
    private uploadService: UploadService,
    private channelService: ChannelService,
    private ngbDateParserFormatter: NgbDateParserFormatter) {
  }

  ngOnInit() {
    this.abortUploadSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.abortUploadSubject.complete();
  }

  handleChange() {
    console.log(this.dateFromModel);
  }

  public closeDialog(): void {
    this.cancel();
    this.activeModal.close('Close click');//evento chiusura modale
  };

  public cancel(): void {
    this.aborted = true;
    this.abortUploadSubject.next();
  }

  public onFileChanged(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.arrayFiles.push(file);
      }
    }
  }

  public removeFile(event: Event, file) {
    event.stopPropagation();
    let fileIndex: number = this.arrayFiles.indexOf(file);
    if (fileIndex > -1) {
      this.arrayFiles.splice(fileIndex, 1);
    }
  }

  public filesDropped(files: FileHandle[]): void {
    if (files) {
      for (let fl of files) {
        this.arrayFiles.push(fl.file);
      }
    }
  }

  /**
   * porta allo stato successivo del modale in cui vengono selezionati i parametri degli allegati 
   */

  public next(): void {
    this.selectedFiles = this.arrayFiles;
  }

  /**
   * porta allo stato precedente e permette di selezionare altri file o rimuovere quelli già presenti
   */

  public previous(): void {
    this.selectedFiles = [];
  }

  public async upload(): Promise<void> {
    let _self = this;
    if (!_self.selectedFiles || _self.selectedFiles.length <= 0) {
      _self.utils.showMessageToast("dialog.upload-files.error.no-file-selected-error", ToastTypes.ERROR);
      return Promise.resolve();
    }

    //todo valido le date
    if (!_self.dateFromModel) {
      _self.utils.showMessageToast("dialog.upload-files.error.invalid-dates", ToastTypes.ERROR);
      return Promise.resolve();
    }

    _self.uploading = true;
    _self.utils.setDialogUnclosable(true);
    let errors: Array<ChannelAttachmentMediaFile> = [];
    for (let i = 0; i < _self.selectedFiles.length; i++) {
      let file = _self.selectedFiles[i];
      _self.currentFile = i;
      _self.totalFiles = _self.selectedFiles.length;
      _self.currentUploadProgress = 0;
      try {
        let data: ManagedUpload.SendData = await _self.uploadService.uploadFile(file, 'tmp/media/media-file/', _self.abortUploadSubject, (progress: ManagedUpload.Progress) => {
          if (new Date().getTime() - _self.lastUpdate > 250) {
            _self.currentUploadProgress = parseFloat(((progress.loaded * 100) / progress.total).toFixed(2));
            _self.lastUpdate = new Date().getTime();
          }
        });
        let uploadedFile: ChannelAttachmentMediaFile = new ChannelAttachmentMediaFile();
        uploadedFile.channelId = _self.channelId;
        uploadedFile.mediaFileId = null;
        uploadedFile.name = file.name;
        uploadedFile.mimeType = file.type;
        uploadedFile.url = data.Key;
        uploadedFile.tmpUrl = data.Location;
        uploadedFile.previewUrl = null; //todo
        uploadedFile.attributes = "";
        uploadedFile.description = "";
        uploadedFile.storageType = "S3";
        uploadedFile.translations = null;
        uploadedFile.enabledFrom = _self.ngbDateParserFormatter.format(_self.dateFromModel);
        if (_self.dateToModel) {
          uploadedFile.enabledTo = _self.ngbDateParserFormatter.format(_self.dateToModel);
        }
        _self.currentUploadProgress = 100;
        _self.uploadingPreview = true;
        //let mediaFile: MediaFile = await _self.fileService.createFile(uploadedFile);
        let updatedMediaFile = await _self.controlImage(file, uploadedFile);
        _self.uploadingPreview = false;
        if (_self.aborted) {
          throw ErrorCodes.UPLOAD_ABORTED;
        }
      } catch (err) {
        if (err == ErrorCodes.UPLOAD_ABORTED) {
          _self.utils.showMessageToast("dialog.upload-files.error.upload-aborted", ToastTypes.ERROR);
          _self.utils.setDialogUnclosable(false);
          this.activeModal.close('upload error');//evento chiusura modale
          return;
        }
        console.error("upload file error", file, err);
        errors.push(file);
      }
    }

    if (errors.length > 0) {
      let filesList: string = "";
      for (let i = 0; i < errors.length; i++) {
        filesList += errors[i].name;
        if (i + 1 != errors.length) {
          filesList += ", "
        }
      }
      let message: string = await _self.utils.translateKeyWithData("dialog.upload-files.error.upload-error", { files: filesList });
      _self.utils.showMessageToast(message, ToastTypes.ERROR);
    } else {
      _self.utils.showMessageToast("dialog.upload-files.success.upload-success", ToastTypes.SUCCESS);
    }
    _self.utils.setDialogUnclosable(false);
    _self.activeModal.close();
  }

  public controlImage(file: any, channelAttachmentUpload: ChannelAttachmentMediaFile): Promise<void> {
    let _self = this;
    if (file.type.indexOf("image") >= 0) {
      return new Promise<void>((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossorigin", "anonymous");
        img.addEventListener('error', function () {
          return resolve();
        });
        img.onload = function () {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          canvas.height = canvas.width * (img.height / img.width);
          // step 1 - resize to 50%
          let oc = document.createElement('canvas');
          let octx = oc.getContext('2d');
          oc.width = img.width * 0.5;
          oc.height = img.height * 0.5;
          octx.drawImage(img, 0, 0, oc.width, oc.height);

          //in caso di png devo risvuotare la trasparenza prima del resize
          let oc2 = document.createElement('canvas');
          let oc2tx = oc2.getContext('2d');
          oc2.width = oc.width;
          oc2.height = oc.height;
          oc2tx.drawImage(oc, 0, 0, oc.width, oc.height);

          // step 2
          octx.clearRect(0, 0, oc.width, oc.height);
          octx.drawImage(oc2, 0, 0, oc2.width * 0.5, oc2.height * 0.5);
          // step 3, resize to final size
          ctx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5,
            0, 0, canvas.width, canvas.height);
          let base64 = canvas.toDataURL();
          _self.uploadService.uploadBase64(base64, 'tmp/preview/media-preview/', _self.abortUploadSubject, null).then((result: string) => {
            channelAttachmentUpload.previewUrl = result;
            return _self.channelService.addChannelAttachment(channelAttachmentUpload);
            //return _self.fileService.editPreview(channelAttachmentUpload);
          }).then(() => {
            resolve();
          }).catch((e: Error) => {
            reject(e);
          });
        }
        img.src = channelAttachmentUpload.tmpUrl;
      });
    }

    if (file.type.indexOf("video") >= 0) {
      return new Promise<void>((resolve, reject) => {
        let video = document.createElement('video');
        video.setAttribute("crossorigin", "anonymous");
        video.addEventListener('error', function () {
          return resolve();
        });
        video.onloadedmetadata = function () {
          let duration = video.duration;
          let middle = duration / 2;
          middle = Math.floor(middle);
          video.currentTime = middle;
        };

        video.onseeked = function (e) {
          let canvas = document.createElement('canvas');
          canvas.height = video.videoHeight;
          canvas.width = video.videoWidth;
          let ctx = canvas.getContext('2d');
          //ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // step 1 - resize to 50%
          let oc = document.createElement('canvas');
          let octx = oc.getContext('2d');
          oc.height = video.videoHeight * 0.5;
          oc.width = video.videoWidth * 0.5;
          octx.drawImage(video, 0, 0, oc.width, oc.height);
          // step 2
          octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);
          // step 3, resize to final size
          ctx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5,
            0, 0, canvas.width, canvas.height);

          let base64 = canvas.toDataURL();
          _self.uploadService.uploadBase64(base64, 'tmp/preview/media-preview/', _self.abortUploadSubject, null).then((result: string) => {
            channelAttachmentUpload.previewUrl = result;
            return _self.channelService.addChannelAttachment(channelAttachmentUpload);
          }).then((data: any) => {
            resolve(data);
          }).catch((e: Error) => {
            reject(e);
          });
        };

        video.src = channelAttachmentUpload.tmpUrl;
      });
    }

    return Promise.resolve();
  }
}
