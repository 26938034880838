import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import videojs, { VideoJsPlayer } from 'video.js';
import { VideoJsPlayerHLS } from 'src/typings';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClientModule } from '@angular/common/http';
// import { EventEmitter } from 'events';
require('videojs-contrib-quality-levels');
require('videojs-hls-quality-selector');

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {

  @ViewChild('overlayChat', { static: true, read: ElementRef })
  public overlayChat: ElementRef;


  @Input() options: {
    fluid: boolean,
    aspectRatio: string,
    autoplay: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };
  @Output() action = new EventEmitter();
  deviceInfo = null;

  public player: VideoJsPlayerHLS;
  public playerError: boolean = false;

  constructor(private deviceService: DeviceDetectorService
  ) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

  }

  onAction(event): void {
    if (event) {
      this.overlayChat.nativeElement.classList.add('overlayChatMobileFullscreen');
    }
  }
  /*
  epicFunction() {
    console.log('hello `Home` component');
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }*/

  ngOnInit() {
    let _self = this;
    // instantiate Video.js
    _self.player = <VideoJsPlayerHLS>videojs("channel-main-player", _self.options, function onPlayerReady() {
      let myPlayer: VideoJsPlayerHLS = this;
      let id: string = myPlayer.id();
      myPlayer.on('error', (event) => {
        _self.playerError = true;
        let time: number = myPlayer.currentTime();
        setTimeout(() => {
          myPlayer.dispose();
          document.getElementById("video-player-component-video-container").innerHTML =
            `<video id="channel-main-player" class="video-js vjs-default-skin vjs-16-9" controls playsinline
              preload="none" crossorigin="anonymous">
          </video>`;
          _self.ngOnInit();
        }, 3000);
      });

      myPlayer.on("loadeddata", (event) => {
        console.log("loadeddata", event)
        _self.playerError = false;
      });
    });

    // EVENTO FULLSCREEN VIDEOPLAYER
    _self.player.on('fullscreenchange', () => {
      _self.action.emit(_self.player.isFullscreen());
    });

    /*_self.player.on("error", (err) => {
      console.error("errore canale non in trasmissione", err);
      setTimeout(() => {
        console.log("riprovo");
        _self.player.dispose();
        _self.ngOnInit();
        _self.options.sources[0].src = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";
      }, 3000);
    });*/
  }


  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  public setVideo(src: Array<videojs.Tech.SourceObject>) {
    this.player.src(src);
    this.player.controls(true);
    this.player.play();
    this.player.hlsQualitySelector({
      displayCurrentQuality: true,
    });
  }
}