import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChannelService } from 'src/app/Services/Channel/channel.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ChannelAttachmentMediaFile } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-channel-attachments',
  templateUrl: './channel-attachments.component.html',
  styleUrls: ['./channel-attachments.component.scss']
})
export class ChannelAttachmentsComponent implements OnInit {
  @Input() channelId: string;
  public attachments: Array<ChannelAttachmentMediaFile>;

  constructor(private activeModal: NgbActiveModal,
    private utils: UtilsService,
    private channelService: ChannelService) { }

  ngOnInit() {
    let _self = this;

    _self.channelService.getChannelAttachments(_self.channelId).then((attachments) => {
      _self.attachments = attachments;
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    });
  }

  public closeDialog(): void {
    this.activeModal.close('Close click');//evento chiusura modale
  };

}
