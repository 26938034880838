import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User, GetUsersPayload, UserAuthorizationCodes, UserAuthorization } from 'src/app/Utils/common-classes';
import { ManageUsersService } from 'src/app/Services/Admin/ManageUsers/manage-users.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageUsersComponent implements OnInit {
  public pendingUsers: Array<User>;
  public pendingListUsers: Array<User>;
  public pendingUsersFilter = new FormControl('');
  public pendingUsersPage = 1;
  public pendingUsersPageSize = 10;
  public pendingUsersCollectionSize = 0;

  public activeUsers: Array<User> = [];
  public activeListUsers: Array<User>;
  public activeUsersFilter = new FormControl('');
  public activeUsersPage = 1;
  public activeUsersPageSize = 10;
  public activeUsersCollectionSize = 0;

  public inactiveUsers: Array<User> = [];
  public inactiveListUsers: Array<User>;
  public inactiveUsersFilter = new FormControl('');
  public inactiveUsersPage = 1;
  public inactiveUsersPageSize = 10;
  public inactiveUsersCollectionSize = 0;


  private fragmentSubscription: Subscription;

  constructor(
    private manageUsersService: ManageUsersService,
    public utils: UtilsService,
    public route: ActivatedRoute) {

    let _self = this;
    this.fragmentSubscription = this.route.fragment.subscribe((fragment: string) => {
      _self.navChanged(fragment);
    });
  }

  ngOnInit() {
    let _self = this;
  }

  ngOnDestroy(): void {
    this.fragmentSubscription.unsubscribe();
  }

  public navChanged(fragment: string) {
    let _self = this;
    _self.pendingListUsers = null;
    _self.pendingUsers = null;
    _self.pendingUsersFilter = new FormControl('');
    _self.activeUsersFilter = new FormControl('');
    _self.inactiveUsersFilter = new FormControl('');
    _self.pendingUsersPage = 1;
    _self.inactiveUsersPage = 1;
    _self.activeUsersPage = 1;
    switch (fragment) {
      case "ManageUserRequests":
        _self.initManageUserRequests();
        break;
      case "ManageActiveUsers":
        _self.initManageUsers();
        break;
      default:
        break;
    }
  }

  /** GESTIONE UTENTI IN ATTESA **/

  private initManageUserRequests(): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageUsersService.getPendingUsers().then((users: Array<User>) => {
      _self.pendingUsers = users;
      _self.refreshPendingUsers();
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public handleUserRequest(accessGranted: boolean, platformAdmin: boolean, user: User): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageUsersService.handleUserRequest(accessGranted, platformAdmin, user).then(() => {
      return _self.manageUsersService.getPendingUsers();
    }).then((users: Array<User>) => {
      _self.pendingUsers = users;
      _self.refreshPendingUsers();
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public refreshPendingUsers(): void {
    let _self = this;
    let tmpUser: Array<User> = _self.search(_self.pendingUsers, _self.pendingUsersFilter.value);
    _self.pendingUsersCollectionSize = tmpUser.length;
    _self.pendingListUsers = tmpUser.slice((_self.pendingUsersPage - 1) * _self.pendingUsersPageSize, (_self.pendingUsersPage - 1) * _self.pendingUsersPageSize + _self.pendingUsersPageSize);
  }

  /** GESTIONE UTENTI IN ATTESA **/


  /** GESTIONE UTENTI PIATTAFORMA **/

  /** GESTIONE UTENTI ATTIVI **/
  private initManageUsers(): void {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageUsersService.getUsers().then((getUsersPayload: GetUsersPayload) => {
      _self.activeUsers = getUsersPayload.activeUserList;
      _self.refreshActiveUsers();
      _self.inactiveUsers = getUsersPayload.inactiveUserList;
      _self.refreshInactiveUsers();
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  public refreshActiveUsers(): void {
    let _self = this;
    let tmpUser: Array<User> = _self.search(_self.activeUsers, _self.activeUsersFilter.value);
    _self.activeUsersCollectionSize = tmpUser.length;
    _self.activeListUsers = tmpUser.slice((_self.activeUsersPage - 1) * _self.activeUsersPageSize, (_self.activeUsersPage - 1) * _self.activeUsersPageSize + _self.activeUsersPageSize);
  }

  public changeActiveUserRole(user: User, isAdmin: boolean): void {
    let _self = this;
    _self.utils.showShortLoader();
    let roles: Array<string> = [UserAuthorizationCodes.BASIC];
    if (isAdmin) {
      roles.push(UserAuthorizationCodes.ADMIN);
    }
    _self.manageUsersService.editUserRole(user, roles).then((editedUser: User) => {
      return _self.manageUsersService.getUsers();
    }).then((getUsersPayload: GetUsersPayload) => {
      _self.activeUsers = getUsersPayload.activeUserList;
      _self.refreshActiveUsers();
      _self.inactiveUsers = getUsersPayload.inactiveUserList;
      _self.refreshInactiveUsers();
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }

  /** GESTIONE UTENTI ATTIVI **/

  /** GESTIONE UTENTI INATTIVI **/


  public refreshInactiveUsers(): void {
    let _self = this;
    let tmpUser: Array<User> = _self.search(_self.inactiveUsers, _self.inactiveUsersFilter.value);
    _self.inactiveUsersCollectionSize = tmpUser.length;
    _self.inactiveListUsers = tmpUser.slice((_self.inactiveUsersPage - 1) * _self.inactiveUsersPageSize, (_self.inactiveUsersPage - 1) * _self.inactiveUsersPageSize + _self.inactiveUsersPageSize);
  }
  /** GESTIONE UTENTI INATTIVI **/


  public ChangeUserStatus(user: User, accessGranted: boolean) {
    let _self = this;
    _self.utils.showShortLoader();
    _self.manageUsersService.handleUserRequest(accessGranted, false, user).then(() => {
      return _self.manageUsersService.getPendingUsers();
    }).then((users: Array<User>) => {
      return _self.manageUsersService.getUsers();
    }).then((getUsersPayload: GetUsersPayload) => {
      _self.activeUsers = getUsersPayload.activeUserList;
      _self.refreshActiveUsers();
      _self.inactiveUsers = getUsersPayload.inactiveUserList;
      _self.refreshInactiveUsers();
    }).catch((err) => {
      _self.utils.getErrorShowToast(err);
    }).finally(() => {
      _self.utils.hideShortLoader();
    });
  }
  /** GESTIONE UTENTI PIATTAFORMA **/

  private search(users: Array<User>, text: string): Array<User> {
    return users.filter((user: User) => {
      const term = text.toLowerCase();
      return user.forename.toLowerCase().includes(term)
        || user.surname.toLowerCase().includes(term)
        || user.email.toLowerCase().includes(term)
        || (user.forename.toLowerCase() + " " + user.surname.toLowerCase()).includes(term);
    });
  }
  public isAdmin(user: User): boolean {
    if (user.authorizations && user.authorizations.length > 0) {
      let authorization: UserAuthorization = user.authorizations.find((authorization: UserAuthorization) => {
        return authorization.authorizationCode.toLowerCase() == UserAuthorizationCodes.ADMIN.toLowerCase();
      });

      return authorization != null;
    }
    return false;
  }

}
