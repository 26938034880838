export class StorageKeys {
    public static ACCESS_TOKEN: string = "ACCESS_TOKEN";
    public static APPLICATION_DATA: string = "APPLICATION_DATA";
    public static LANGUAGE: string = "LANGUAGE";
}

export class LoginPayload {
    public applicationData: ApplicationDataPayload;
    public accessToken: string;
}

export class JoinChannelPayload extends LoginPayload {
    public channelId: string;
}

export class ApplicationDataPayload {
    public user: User;
    public channelId: string; //canale redirect per utenti temporanei
}

export class ErrorCodes {
    public static INVALID_PATH: string = "INVALID_PATH";
    public static MISSING_REQUIRED_DATA = "MISSING_REQUIRED_DATA";
    public static INVALID_SERVICE: string = "INVALID_SERVICE";
    public static INVALID_FOLDER_NAME: string = "INVALID_FOLDER_NAME";
    public static DUPLICATE_FILE_NAME: string = "DUPLICATE_FILE_NAME";
    public static DUPLICATE_FOLDER_NAME: string = "DUPLICATE_FOLDER_NAME";
    public static PARENT_FOLDER_NOT_FOUND: string = "PARENT_FOLDER_NOT_FOUND";
    public static DESTINATION_IS_SUBFOLDER: string = "DESTINATION_IS_SUBFOLDER";
    public static USER_NOT_FOUND: string = "USER_NOT_FOUND";
    public static USER_NOT_AUTHORIZED: string = "USER_NOT_AUTHORIZED";
    public static INVALID_RECOVERY_CODE: string = "INVALID_RECOVERY_CODE";
    public static INVALID_PASSWORD: string = "INVALID_PASSWORD";
    public static FILE_NOT_FOUND: string = "FILE_NOT_FOUND";
    public static FOLDER_NOT_FOUND: string = "FOLDER_NOT_FOUND";
    public static TREE_NOT_FOUND: string = "TREE_NOT_FOUND";
    public static USER_REQUEST_PENDING: string = "USER_REQUEST_PENDING";
    public static SESSION_NOT_FOUND: string = "SESSION_NOT_FOUND";
    public static CHANNEL_PARTICIPANT_ALREADY_INVITED: string = "CHANNEL_PARTICIPANT_ALREADY_INVITED";
    public static DUPLICATE_TREE_NAME: string = "DUPLICATE_TREE_NAME";
    public static UNKNOWN_ERROR: string = "UNKNOWN_ERROR";
    public static CONNECTION_REFUSED: string = "CONNECTION_REFUSED";
    public static LOAD_PREVIEW_ERROR: string = "LOAD_PREVIEW_ERROR";
    public static UPLOAD_ABORTED: string = "UPLOAD_ABORTED";
    public static OBJECT_ALREADY_EXISTS: string = "OBJECT_ALREADY_EXISTS";
    public static USER_EMAIL_NOT_VERIFIED: string = "USER_EMAIL_NOT_VERIFIED";
    public static USER_PHONE_NOT_VERIFIED: string = "USER_PHONE_NOT_VERIFIED";
    public static CHANNEL_NOT_FOUND: string = "CHANNEL_NOT_FOUND";
    public static INVALID_EMAIL_VERIFICATION_CODE: string = "INVALID_EMAIL_VERIFICATION_CODE";
    public static INVALID_PHONE_VERIFICATION_CODE: string = "INVALID_EMAIL_VERIFICATION_CODE";
    public static USER_EMAIL_ALREADY_VERIFIED: string = "USER_EMAIL_ALREADY_VERIFIED";
    public static USER_PHONE_ALREADY_VERIFIED: string = "USER_PHONE_ALREADY_VERIFIED";
    public static INVITE_NOT_FOUND: string = "INVITE_NOT_FOUND";
}

export class StatusCodes {
    public static INIT: string = "INIT"; // non è ancora stato creato il canale su AWS
    public static CREATING: string = "CREATING"; // il canale su AWS è in fase di creazione
    public static CREATED: string = "CREATED"; // il canale su AWS è in fase di creazione
    public static STARTING: string = "STARTING"; // il canale su AWS è in fase di start
    public static STARTED: string = "STARTED"; // il canale su AWS è attivo e pronto a ricevere/trasmettere
    public static STOPPING: string = "STOPPING"; // il canale su AWS è in fase di stop
    public static STOPPED: string = "STOPPED"; // il canale su AWS è inattivo
    public static DELETING: string = "DELETING"; // il canale su AWS è inattivo
    public static DELETED: string = "DELETED"; // il canale su AWS è stato eliminato
    public static ERROR: string = "ERROR"; // errore durante la creazione di aws settings
}

export class ToastTypes {
    public static STANDARD: string = "STANDARD";
    public static SUCCESS: string = "SUCCESS";
    public static ERROR: string = "ERROR";
}

export class SocialAuthMethods {
    public static GOOGLE: string = "GOOGLE";
    public static FACEBOOK: string = "FACEBOOK";
    public static APPLE: string = "APPLE";
}

export class Error {
    public error: any;

    constructor(error: any) {
        this.error = error;
    }
}

export class Language {
    public languageId: string;
    public name: string;
}

export class User {
    public userId: string;
    public tenantId: string;
    public active: boolean;
    public forename: string;
    public surname: string;
    public username: string;
    public email: string;
    public cid: string;
    public nickname: string;
    public tempUserCode: string;
    public activateUserCode: string;
    public recoveryPassCode: string;
    public modifyDate: Date;
    public modifyUserid: string;
    public mailDisabled: string;
    public phoneNumber: string;
    public langCode: string; // 2 caratteri minuscoli ("it", "en", ecc)
    public timezone: string; // Nel formato "Europe/Rome"
    public avatarImage: string;
    public password: string;
    public loginDate: Date;
    public authorizations: Array<UserAuthorization>;
    public moderator: boolean;

    public error: string;
    public checked: boolean;
    public owner: boolean;
}

export class UserAuthorization {
    public userId: string;
    public authorizationCode: string;
    public creationDate: Date;
}

export class Channel {
    public channelId: string;
    public userId: string;
    public tenantId: string;
    public name: string;
    public description: string;
    public url: string;
    public status: string;
    public duration: number;
    public awsInitDate: Date;
    public startDate: Date;
    public creationDate: Date;
    public modifyDate: Date;
    public active: boolean;
    public privateChannel: boolean;
    public users: Array<User>
    public owner: User;
}

export class ChannelAuthorization {
    public static CHAT_WRITE: string = "CHAT_WRITE";

}

export class ChannelInfo {
    public channel: Channel;
    public moderators: Array<ChannelModerator>;
    public settings: ChannelAwsSettings;
    public onlineUsers: Array<User>;

    public static isModerator(channelInfo: ChannelInfo, userId: string): boolean {
        if (channelInfo.channel && channelInfo.moderators) {
            if (channelInfo.channel.userId == userId) {
                return true;
            }

            for (let i = 0; i < channelInfo.moderators.length; i++) {
                const moderator = channelInfo.moderators[i];
                if (moderator.userId == userId) {
                    return true;
                }
            }
        }
        return false;
    }
}

export class ChannelParticipant {
    public channelParticipantId: string;
    public channelId: string;
    public userId: string;
    public senderUserId: string;
    public moderator: boolean;
    public creationDate: Date;
    public modifyDate: Date;
    public active: boolean;
    public notifications: boolean;
}

export class ChannelModerator {
    public channelId: string;
    public userId: string;
    public creationDate: Date;
    public modifyDate: Date;
    public active: boolean;
}

export class ChannelChatMessage {
    public channelChatMessageId: string;
    public userId: string;
    public channelId: string;
    public message: string;
    public authorized: boolean;
    public creationDate: Date;
    public modifyDate: Date;
    public active: boolean;
}

export class ChatMessagePayload {
    public message: ChannelChatMessage;
    public sender: User;
    public color: string;
}

export class UserSession {
    public userSessionId: string;
    public userId: string;
    public token: string;
    public active: boolean;
    public creationDate: Date;
    public lastActionDate: Date;
}

export class MediaTranslation {
    name: string;
    description: string;
    mediaTranslationId: string;
    languageId: string;
    mediaFileId: string;
    mediaFolderId: string;
}

export class ConfirmDialogData {
    public title: string;
    public message: string;
}

export class ChannelAwsSettings {
    public channelAwsSettingsId: string;
    public channelId: string;
    public packageEndpointId: string;
    public packageChannelId: string;
    public liveInputEndpointId: string;
    public liveChannelId: string;
    public inputAServerRtmp: string;
    public inputASecretKey: string;
    public inputBServerRtmp: string;
    public inputBSecretKey: string;
    public creationDate: Date;
    public modifyDate: Date;
    public active: boolean;
}

export class GetUsersPayload {
    public activeUserList: Array<User>;
    public inactiveUserList: Array<User>;
}

export function buildMap(obj: object): Map<string, any> {
    let map = new Map();
    Object.keys(obj).forEach(key => {
        map.set(key, obj[key]);
    });
    return map;
}

export class UserAuthorizationCodes {
    public static ADMIN: string = "ADMIN";
    public static BASIC: string = "BASIC";
}

export class CancelAsyncToken {
    public cancel: boolean;
}

export class TranslationData {
    public host: boolean;
    public translationId: string;
    public translationName: string;
    public translationLangCode: string;
}

export class ChannelAttachment {
    public channelAttachmentId: number;
    public channelMediaFolderId: number;
    public mediaFileId: string;
    public name: string;
    public enabledFrom: string;
    public enabledTo: string;
    public privateAttachment: boolean;
    public creationDate: string;
    public modifyDate: string;
    public active: boolean;
}

export class ChannelAttachmentMediaFile extends ChannelAttachment {
    public channelId: string;
    public mimeType: string;
    public url: string;
    public tmpUrl: string;
    public previewUrl: string;
    public attributes: string;
    public description: string;
    public storageType: string;
    public translations: string;

}

export class ChannelAttachmentFolder {
    public channelAttachmentFolderId: number;
    public parentAttachmentFolderId: number;
    public name: string;
    public creationDate: string;
    public modifyDate: string;
    public active: boolean;
}

export class PhoneSelectedCountry {
    public dialCode: string;
    public iso2: string
}