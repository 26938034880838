import { Injectable } from '@angular/core';
import { User, GetUsersPayload } from 'src/app/Utils/common-classes';
import { AuthService } from '../../Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  constructor(private authService: AuthService) { }


  public getPendingUsers(): Promise<Array<User>> {
    return this.authService.getRequest("/get-pending-users", null, true);
  }

  public handleUserRequest(accessGranted: boolean, platformAdmin: boolean, user: User): Promise<Array<User>> {
    const body = {
      accessGranted: accessGranted,
      platformAdmin: platformAdmin,
      userIds: [user.userId]
    }
    return this.authService.postRequest("/handle-user-requests", body);
  }

  public getUsers(): Promise<GetUsersPayload> {
    return this.authService.getRequest("/get-users", null, true);
  }

  public editUserRole(user: User, roles: Array<string>): Promise<User> {
    const body = {
      userId: user.userId,
      roles: roles
    }
    return this.authService.postRequest("/edit-user", body, true);
  }
}
