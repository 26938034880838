import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from 'src/app/Services/Utils/utils.service';
import { ToastTypes } from 'src/app/Utils/common-classes';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  @Input() registerUrl: string;
  @Input() joinUrl: string;

  constructor(private activeModal: NgbActiveModal, private utilsService: UtilsService) { }

  ngOnInit() {
  }


  public closeDialog(): void {
    this.activeModal.close('Close click');//evento chiusura modale
  };

  public copyToClipboard(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.utilsService.showMessageToast('general.success.copy', ToastTypes.SUCCESS);
  }
}
